import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Col, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { baseurl } from '../apiurl'
import { getUser } from '../assets/redux/action/userProfileAction'
import Footer from '../container/Footer'
import NavigationBar from '../container/NavigationBar'

const Edit = (props) => {
    console.log(props.location.state)
    const history=useHistory()
    // const [validated, setValidated] = useState(false);
    const fname=props?.location?.state?.first_name
    const lname=props?.location?.state?.last_name
    const mail=props?.location?.state?.email
    const gendr=props?.location?.state?.gender
    const contact=props?.location?.state?.mobile
    let [email,setEmail]=useState(mail)
    let [firstname,setFirstName]=useState(fname)
    let [lastName,setLastName]=useState(lname)
    let [gender,setGender]=useState(gendr)
    const [mobileNumber,setNumber]=useState(contact)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getUser())
    },[dispatch])
    const userdetail = useSelector(state => state.userdetail)
    console.log(userdetail)
    const dat=userdetail.userdata.data
    console.log("data",dat)
    let data=new FormData()
        data.append('first_name',firstname)
        data.append('last_name',lastName)
        data.append('email',email)
        // gendr===null?data.append('gender',""):
        data.append('gender',gender)
        data.append('mobile_no',mobileNumber)
    const updateUser=(e)=>{
        
        // const form = e.currentTarget
        // if (form.checkValidity() === false) {
            e.preventDefault()
            // e.stopPropagation()
            
        // }else{
        //   setValidated(true)
        
        // setEmail(dat?.email)
        // setFirstName(dat?.first_name)
        // setLastName(dat?.last_name)
        // setGender(dat?.gender)
        // console.log("fame",firstname)
        // console.log("lnmae",lastName)
        // console.log("email",email)
        // console.log("gender",gender)
        // console.log("mobile",mobileNumber)
        // let obj={"email":email,"first_name":firstname,"last_name":lastName,"gender":gender,"mobile_no":mobileNumber}
        
        console.log(data)
        let y=localStorage.getItem('token')
        axios.post(baseurl+'users/user-profile',data,{headers:{'Authorization':y}})
        .then(response=>{
            console.log(response)
            history.push('/user-profile')
        })
        .catch(error=>{
            console.log((error.response))
        })
    // }
        
    }
    const checkLogin=()=>{
        let check=localStorage.getItem("token")
        if(check){

        }else{
            history.push("/")
        }
    }
    // console.log("console")
    return (
        <div className="edit" onLoad={checkLogin()}>
            <NavigationBar />
            
            <Form  className="edit-form" onSubmit={updateUser} >
                <h2 className="text-center pt-5 pb-3">Edit user profile</h2>
                <Form.Row>
                <Form.Group as={Col} lg={12} controlId="firstname">
                    <Form.Control type='text' placeholder="firstName" value={firstname}   onChange={e=>setFirstName(e.target.value)} />
                </Form.Group>
                <Form.Group as={Col} lg={12} controlId="lastname">
                    <Form.Control type='text' placeholder="Last Name" value={lastName} onChange={e=>setLastName(e.target.value)} />
                </Form.Group>
                <Form.Group as={Col} lg={12} controlId="email">
                    <Form.Control type='email' disabled placeholder="email" value={email} onChange={e=>setEmail(e.target.value)} />
                </Form.Group>
                <Form.Group as={Col} lg={12} controlId="gender">
                        <Form.Control as="select"  value={gender} className="fas fa-angle-down" placeholder="gender *" onChange={e=>setGender(e.target.value)}>
                        <option value="">Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Provide gender please</Form.Control.Feedback>
                    </Form.Group>
                <Form.Group as={Col} lg={12} controlId="mobi">
                    <Form.Control type="tel" placeholder="Mobile Number " value={mobileNumber} onChange={e=>setNumber(e.target.value)} />
                    {/* <Form.Control.Feedback type="invalid">please update mobile number</Form.Control.Feedback> */}
                    {/* <Form.Control type="tel"  placeholder="Mobile number * " value={mobileNumber} onChange={e=>setNumber(e.target.number)} /> */}
                </Form.Group>
                    {/* <div className="button-combo"> */}
                        <Col lg={6} xs={6} >
                            <Button className="cancel-button btn-block" onClick={()=>history.goBack()}>Cancel</Button>
                        </Col>
                        <Col lg={6} xs={6} >
                        <Button type="submit" className="save-button btn-block" onClick={updateUser}>Save</Button>
                        </Col>
                    {/* </div> */}
                </Form.Row>
            </Form>
            <Footer />
        </div>
    )
}

export default Edit

const initialState={
    loading:false,
    topiclist:[],
    subtopiclist:[],
    error:""
}
const topicListReducer=(state=initialState,action)=>{
    switch(action.type){
        case 'GET_TOPIC_LIST_REQUEST':
            return{
                ...state,
                loading:true
            }
        case 'GET_TOPIC_LIST_SUCCESS':
            return{
                ...state,
                loading:false,
                topiclist:action.payload,
                error:""
            }
        case 'GET_TOPIC_LIST_FAILURE':
            return{
                ...state,
                loading:false,
                topiclist:[],
                error:action.payload
            }
        case 'GET_SUBTOPIC_LIST_REQUEST':
            return{
                ...state,
                loading:true
            }
        case 'GET_SUBTOPIC_LIST_SUCCESS':
            return{
                ...state,
                loading:false,
                topiclist:action.payload,
                error:""
            }
        case 'GET_SUBTOPIC_LIST_FAILURE':
            return{
                ...state,
                loading:false,
                topiclist:[],
                error:action.payload
            }
        
        default :
            return state
    }
}
export default topicListReducer
import React from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

const Logout = () => {
    const history=useHistory()
    const handleLogout=()=>{
        localStorage.removeItem("token")
        history.push('/login')
    }
    return (
        <div onClick={()=>handleLogout()} >
            <i className="fas fa-sign-out-alt pr-2" />
            <span >Logout</span>
            {/* <Button className="login-button btn-block" onClick={handleLogout}>Log out</Button> */}
        </div>
    )
}

export default Logout

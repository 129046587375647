import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Signup from './Signup'
import SelectTopic from './SelectTopic'
import LandingPage from './LandingPage'
import TopicPage from './TopicPage'
import Question from './Question'
import Login from './Login'
import SelectedTopic from './SelectedTopic'
import ForgotPassword from './ForgotPassword'
import ConfirmPassword from './ConfirmPassword'
import UserProfile from './UserProfile'
import Edit from './Edit'
import Home from './Home'
import NotFound from '../container/NotFound'
import Subtopic from './Subtopic'
import TopicTab from './TopicTab'
import Terms from '../container/Terms'
import Privacy from '../container/Privacy'
import NewPage from './NewPage'
import CreateQuiz from './Create Quiz/CreateQuiz'
import Footer from '../container/Footer'
import About from '../container/About'
import Loader from './Loader'
import Question2 from './Question2'
import ChangePassword from './ChangePassword'
const Routes = () => {
    return (
        <div>
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/signup" component={Signup} />
                    <Route path="/login" component={Login} />
                    <Route path="/select-topic" component={SelectTopic} />
                    <Route path="/setting" component={LandingPage} />
                    <Route path="/topic-detail/:topicname" component={TopicPage} />
                    <Route path="/play/:name" component={Question} />
                    <Route path="/selectedtopic" component={SelectedTopic} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <Route path="/reset-password" component={ConfirmPassword} />
                    <Route path="/user-profile" component={UserProfile} />
                    <Route path="/edit-profile" component={Edit} />
                    <Route path="/subtopic-detail/:subtopicsname" component={Subtopic} />
                    <Route path="/topics" component={TopicTab} />
                    <Route path="/terms-and-condition" component={Terms} />
                    <Route path="/privacy-policies" component={Privacy} />
                    <Route path="/create-quiz" component={CreateQuiz} />
                    <Route path="/newpage" component={NewPage} />
                    <Route path="/footer" component={Footer} />
                    <Route path="/about" component={About} />
                    <Route path="/loader" component={Loader} />
                    <Route path="/question2/:name" component={Question2} />
                    <Route path="/change-password" component={ChangePassword} />
                    <Route component={NotFound} />
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default Routes

import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import Footer from './Footer'
import NavigationBar from './NavigationBar'
import aboutus from '../assets/images/aboutus.png'
const About = () => {
    return (
        <div className="about">
            <NavigationBar />
            <Container >
            <Row className="about-row">
                <Col md={6} className="about-section">
                {/* <div className="about-section"> */}
                    <h2 className="text-center">Who Are We ?</h2>
                    <h2 className="d-inline"> TreadRiser</h2>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the
                    1500s, when an unknown<b> printer</b> took a galley of type and scrambled it to
                    make a type specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting, remaining essentially
                    unchanged. It was popularised in the 1960s with the release of Letraset
                    sheets containing Lorem Ipsum passages, and more recently with desktop
                    publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                {/* </div> */}
                </Col>
                <Col md={6} className="d-none d-sm-none d-md-block">
                    <img src={aboutus} alt="about"  />
                </Col>
            </Row>
            </Container>
            <Footer />
        </div>
    )
}

export default About

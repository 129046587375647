import axios from 'axios'
import {baseurl} from '../../../apiurl'
export const getTopicList=()=>{
    return(dispatch=>{
        dispatch(getTopicListRequest())
        let x=localStorage.getItem('token')
        // let y='Token '+x
        let y=x
        // let f=id
        let api
        localStorage.getItem("token")?
        api=baseurl+"topics/":
        api=baseurl+'topics/open-topic-list'
        console.log("apissss",api)
        axios.get(api,{headers:{'Authorization':y}})
        .then(response=>{
            console.log("Action",response)
            dispatch(getTopicListSuccess(response.data.data))
        })
        .catch(error=>{
            console.log(error)
            dispatch(getTopicListFailure(error))
        })
    })
}
export const getTopicListRequest=()=>{
    return{
        type:'GET_TOPIC_LIST_REQUEST'
    }
}
export const getTopicListSuccess=(data)=>{
    return{
        type:'GET_TOPIC_LIST_SUCCESS',
        payload:data
    }
}
export const getTopicListFailure=(error)=>{
    return{
        type:'GET_TOPIC_LIST_FAILURE',
        payload:error
    }
}

// export const getSubTopicList=()=>{
//     return(dispatch=>{
//         dispatch(getSubTopicListRequest())
//         // let x=localStorage.getItem('token')
//         // let y='Token '+x
//         // let f=id
//         let y="20f4ebb2a580d1466cf05ff5ee8df3a640ddebca"
//         let api='https://testapi.io/api/Ajay/subtopics'
//         axios.get(api,{headers:{'Authorization':y}})
//         .then(response=>{
//             console.log(response)
//             dispatch(getSubTopicListSuccess(response.data))
//         })
//         .catch(error=>{
//             console.log(error.message)
//             dispatch(getSubTopicListFailure(error))
//         })
//     })
// }
// export const getSubTopicListRequest=()=>{
//     return{
//         type:'GET_SUBTOPIC_LIST_REQUEST'
//     }
// }
// export const getSubTopicListSuccess=(data)=>{
//     return{
//         type:'GET_SUBTOPIC_LIST_SUCCESS',
//         payload:data
//     }
// }
// export const getSubTopicListFailure=(error)=>{
//     return{
//         type:'GET_SUBTOPIC_LIST_FAILURE',
//         payload:error
//     }
// }

const initialState={
    loading:false,
    topicdetail:[],
    subtopicdetail:[],
    error:""
}
const topicsReducer=(state=initialState,action)=>{
    switch(action.type){
        case 'GET_TOPIC_REQUEST':
            return{
                ...state,
                loading:true
            }
        case 'GET_TOPIC_SUCCESS':
            return{
                ...state,
                loading:false,
                topicdetail:action.payload,
                error:""
            }
        case 'GET_TOPIC_FAILURE':
            return{
                ...state,
                loading:true,
                topicdetail:"",
                error:action.payload
            }
        case 'GET_SUBTOPIC_REQUEST':
            return{
                ...state,
                loading:true
            }
        case 'GET_SUBTOPIC_SUCCESS':
            return{
                ...state,
                loading:false,
                subtopicdetail:action.payload,
                error:""
            }
        case 'GET_SUBTOPIC_FAILURE':
            return{
                ...state,
                loading:false,
                subtopicdetail:[],
                error:action.payload
            }
        default :
            return state
    }
}
export default topicsReducer

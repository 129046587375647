import React, { useEffect } from 'react'
import {  Row} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getPlay } from '../assets/redux/action/playTypeAction'
import { getUser } from '../assets/redux/action/userProfileAction'
import NavigationBar from '../container/NavigationBar'

const LandingPage = () => {
    const dispatch = useDispatch()
    const history=useHistory()
    useEffect(()=>{
        dispatch(getUser())
    },[dispatch])
    const userdetail = useSelector(state => state.userdetail)
    console.log("userdetail",userdetail?.userdata?.data?.play_type)
    useEffect(()=>{
        // alert("Hey")
        if(userdetail?.userdata?.data?.play_type==="revision"){
            document.getElementById("q4").checked=true;
        }else if(userdetail?.userdata?.data?.play_type==="random"){
            document.getElementById("q3").checked=true
        }
    },[userdetail])
    // useEffect(()=>{
    //     let obj={"option":"random"}
    //     dispatch(getPlay(obj))
    // })
    const handleRandom=()=>{
        let obj={"option":"random"}
        dispatch(getPlay(obj))
    }
    const handleRevision=()=>{
        let obj={"option":"revision"}
        dispatch(getPlay(obj))
    }
    const handlePlayType=()=>{
        // alert("Hey")
        // if(userdetail?.userdata?.data?.play_type==="revision"){
        //     document.getElementById("q4").checked=true;
        // }else if(userdetail?.userdata?.data?.play_type==="random"){
        //     document.getElementById("q3").checked=true
        // }
    }
    return userdetail?.userdata?.data?.loading ?(
        <h2>Loading</h2>
        ) : userdetail.error ? (
          <h2>{userdetail.error}</h2>
        ) : (
        <div className="landing" onLoadedMetadata={handlePlayType()}>
            <NavigationBar />
            <div className="landing-box">
            {/* <Col lg={6} className="m-auto">
            <div className='switch'>
                <div className='quality'>
                    <input defaultChecked id='q1' name='q' type='radio' value='q1' />
                    <label htmlFor="q1">Random</label>
                </div>
                <div className='quality'>
                    <input id='q2' name='q' type='radio' value='q2' />
                    <label htmlFor="q2" >Revision</label>
                </div>
                </div>
            </Col> */}
            <Row className="toggle-button ">
                <div className="random quality" onClick={()=>handleRandom()}>
                    <input type="radio" name="q" id="q3" value="q3" />  <label for='q3'>Random</label>
                    </div>
                    
                <div className="random quality" onClick={()=>handleRevision()}>
                    <input type="radio" name="q" id="q4" value="q4" /> <label for='q4'>Revision</label></div>
            </Row>
            <div className="div-button icon" onClick={()=>history.push('/play')}>
                Play
            </div>
            {/* <div className="container">
                <input type="radio" name="opt" id="opt1" />
                <label for="opt1">Random</label>
                <input type="radio" name="opt" id="opt2" />
                <label for="opt2">Revision</label>
            </div> */}
            {/* <div className="container1">
                    <input type="radio" name="rad" id="op1" />
                    <span for="op1" className="ran">Random</span>
                    <input type="radio" name="rad" id="op2" />
                    <div className="ran">Revision</div>
            </div> */}
            </div>
        </div>
    )
}

export default LandingPage

import axios from 'axios'
import {baseurl} from '../../../apiurl'
export const getPlay=(obj)=>{
    return(dispatch=>{
        dispatch(getPlayRequest())
        let y=localStorage.getItem('token')
        let api=baseurl+'users/update-play-option'
        axios.post(api,obj,{headers:{'Authorization':y}})
        .then(response=>{
            console.log("user action",response)
            // const topicdetail=response.data
            dispatch(getPlaySuccess(response))
        })
        .catch(error=>{
            dispatch(getPlayFailure(error.message))
        })
    })
}
export const getPlayRequest=()=>{
    return{
        type:'GET_PLAY_REQUEST'
    }
}
export const getPlaySuccess=(data)=>{
    return{
        type:'GET_PLAY_SUCCESS',
        payload:data
    }
}
export const getPlayFailure=(error)=>{
    return{
        type:'GET_PLAY_FAILURE',
        payload:error
    }
}
import React, { useEffect, useState } from 'react'
import { Button, Container, Dropdown, DropdownButton, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getUser } from '../assets/redux/action/userProfileAction'
import Logout from '../components/Logout'
import logo from '../assets/images/LOGO.png'
import { getPlay } from '../assets/redux/action/playTypeAction'
const NavigationBar = () => {
    const dispatch = useDispatch()
    const history=useHistory()
    const [visible,setVisible]=useState(true)
    useEffect(()=>{
        dispatch(getUser())
    },[dispatch])
    const userdetail = useSelector(state => state.userdetail)
    const handleauth=()=>{
        let log=localStorage.getItem("token")
        if(log){
        }else{
            history.push('/login')
        }
    }
    //condition if user is logged in then user name will be shown
    //otherwise login and signup button
    useEffect(()=>{
        localStorage.getItem("token")?setVisible(true):setVisible(false)
    },[])
    // const handleRevision=()=>{
    //     let obj={"option":"revision"}
    //     dispatch(getPlay(obj))
    //     history.push('/play/'+localStorage.getItem("topic"))
    // }
    return (
        <div className="navbar-container">
        <Container  /*onLoad={handleauth()}*/>
            <Navbar bg="#FFFAEF" expand="lg" className="navigation-bar">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Brand className="nav-brand-logo p-0 icon" onClick={()=>history.push("/")}><span className="bold" style={{color:'#4f4f4f'}}>
                <img src={logo} alt="TreadRiser logo" height="50px"  />
                </span><span className="riser ml-3"><strong>Tread</strong>Riser</span></Navbar.Brand>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto w-100 custom-navbar">
                <Nav.Link onClick={()=>history.push("/setting")} className="d-none d-sm-none">Setting</Nav.Link>
                <Nav.Link className="d-lg-none"><Logout /></Nav.Link>
                {/* <Nav.Link className="d-sm-none"  onClick={()=>handleRevision()} >
                    <i className="fas fa-share-square pr-2" />Do Revision
                </Nav.Link> */}
                <Nav.Link className="" onClick={()=>history.push('/')}>Home</Nav.Link>
                {/* <Nav.Link className="" onClick={()=>history.push('/about')}>About</Nav.Link> */}
                <Nav.Link className="" onClick={()=>history.push('/select-topic')}>Topics</Nav.Link>
                {/* <Nav.Link className="" onClick={()=>history.push('/blog')}>Blog</Nav.Link> */}
                {/* <Nav.Link href="select-topic">Home</Nav.Link> */}
               
                {visible ?
                <><Nav.Link  className="mr-0" onClick={()=>history.push('/user-profile')} >
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Tooltip!</Tooltip>}>
                    <span className="d-inline-block">
                        
                    </span>
                </OverlayTrigger>
                    Hi, {userdetail?.userdata?.data?.first_name}!</Nav.Link>
                    <DropdownButton menuAlign="right" title="" className="drop-down d-none d-lg-block" id="dropdown-menu-align-right" >
                    {/* <Dropdown.Item eventKey="settings"  onClick={()=>handleRevision()} >
                    <i className="fas fa-share-square pr-2" />Do Revision
                    </Dropdown.Item> */}
                    <Dropdown.Item eventKey="" /*onClick={()=>history.push('/logout')}*/ >
                    <Logout />
                    </Dropdown.Item>
                    {/* <Dropdown.Divider />
                    <Dropdown.Item eventKey="4" ><Logout /></Dropdown.Item> */}
                    </DropdownButton>
                </>:
                <>
                    <Nav.Link onClick={()=>history.push('/login')} className="">
                        {/* <Button className=" btn-lg ">
                            Log In
                        </Button> */}
                        Sign In
                    </Nav.Link>
                    <Nav.Link onClick={()=>history.push('/signup')} className="ml-0 mr-0">
                        <Button className="btn-lg navbar-button-primary">
                            Sign Up
                        </Button>
                    </Nav.Link>
                </>
                } 
                </Nav>
            </Navbar.Collapse>
            </Navbar>
            
        </Container>
        </div>
    )
}

export default NavigationBar

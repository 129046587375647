import React, { useState } from 'react'
import FacebookLogin from 'react-facebook-login'
import GoogleLogin from 'react-google-login'
import {  Button, Col, Form, InputGroup } from 'react-bootstrap'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import {baseurl} from '../apiurl'
import LoginNavigation from '../container/LoginNavigation'
import Footer from '../container/Footer'
import NavigationBar from '../container/NavigationBar'
const Signup = () => {
    const [validated, setValidated] = useState(false);
    const [firstName,setFirstName]=useState("")
    const [lastName,setLastName]=useState("")
    const [gender,setGender]=useState("")
    const [mobileNumber,setMobileNumner]=useState("")
    const [email,setEmail]=useState("")
    const [hideconfirm,setHideConfirm]=useState(true)
    const [password,setPassword]=useState("")
    const [confirmpassword,setConfirmPassword]=useState("")
    const [hidepasswordeye,setPasswordeye]=useState(true)
    const handleconfirm=(props)=>{
        hideconfirm?setHideConfirm(false):setHideConfirm(true)
    }
    const handlepassword=(props)=>{
        hidepasswordeye?setPasswordeye(false):setPasswordeye(true)
    }
    const history=useHistory()
    let data = new FormData();
    const responseFacebook = (response) => {
        console.log(response);
        // data.append('first_name', response.first_name);
        // data.append('last_name', response.last_name);
        // data.append('mobile_no', "1231231231");
        // data.append('password', "fp");
        // data.append('email',response.email);
        data.append('id_token', response.accessToken);
        data.append('login_type','FACEBOOK')
        axios.post(baseurl+'users/social-login',data)
        .then(response=>{
            console.log(response)
            localStorage.setItem("token",response.data.data.token)
            history.push('/select-topic')
        })
        .catch(error=>{
            console.log(error.response)
            if(error.response.data.message==="Email already exits."){
                Swal.fire({
                    title:'Email already exits.',
                    icon:'error'
                })
            }
        })
    
      }
    const responseGoogle = (response) => {
       console.log(response);
       console.log(response.profileObj)
        // // data.append('first_name', response.profileObj.givenName);
        // // data.append('last_name', response.profileObj.familyName);
        // // data.append('mobile_no', "1231231231");
        // // data.append('password', "gp");
        // data.append('email',response.profileObj.email);
        data.append('id_token', response.tokenId);
        data.append('login_type','GOOGLE')
        console.log(data)
        axios.post(baseurl+'/users/social-login',data)
        .then(response=>{
            console.log(response)
            localStorage.setItem("token",response.data.data.token)
            history.push('/select-topic')
        })
        .catch(error=>{
            console.log(error)
            if(error.response.data.message==="Email already exits."){
                Swal.fire({
                    title:error.response.data.message,
                    icon:'error'
                })
                }else if(error.response.status===404){
                    Swal.fire(error.response.statusText)
                }
        })
    } 
    const validation=()=>{
        var regex="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
        // if(!password.match(regex)){
        //     Swal.fire("Enter valid password of minimum 8 letters that contains atleast 1 letter,1 number and special character")
        //     return false
        // }
        if(validated===false){
            return false
        }else if(firstName==="" || firstName===" " ||firstName.lenght<2 ||firstName.length>15){
            Swal.fire("First Name invalid",'','warning')
        }else if(lastName==="" || lastName.length<2 || lastName.length>15){
            Swal.fire("Last Name invalid",'','warning')
        }else if(password!==confirmpassword){
            Swal.fire("Password doesnot match or Enter valid password of minimum 8 letters that contains atleast 1 letter,1 number and special character ")
            setValidated(false)
            return false;
        }/*else if(mobileNumber.length<10  || mobileNumber.length>15){
            Swal.fire("Mobile number is invalid")
            return false;
        }else if(gender==="" || gender==="gender"){
            Swal.fire("Please select gender",'','warning')
            return false;
        }*/else if(validated===false){
            return false;
        }else if(validated){
            return true
        }else{
            return true
        }
        // setValidated(true)
        // return true;
    }
    const handleSubmit=(event)=>{
        console.log("event",event)
        event.preventDefault()
        const form = event.currentTarget
    if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
    }
        setValidated(true)
    let isValid=validation()
        data.append('first_name', firstName);
        data.append('last_name', lastName);
        data.append('mobile_no', mobileNumber);
        data.append('password', password);
        data.append('email', email);
        data.append('gender', gender);
        // console.log(data)
        // console.log("fname",firstName)
        // console.log("lname",lastName)
        // console.log("mobile",mobileNumber)
        // console.log("email",email)
        // console.log("gender",gender)
        // console.log("password",password)
    // const isValid=validation()
    if(isValid){
        axios.post(baseurl+'users/signup',data)
        .then(response=>{
            console.log(response)
            localStorage.setItem("token",response.data.data.token)
            history.push('/select-topic')
        })
        .catch(error=>{
            console.log("error",error.response)
            if(error.response.data.message==="Email already exits."){
            Swal.fire({
                title:error.response.data.message,
                icon:'error'
            })
            }else if(error.response.status===404){
                Swal.fire(error.response.statusText)
            }
        })
    }
        // let obj={'first_name':firstName,'last_name':lastName,'gender':gender,'mobile_no':mobileNumber,'password1':password,'email':email}
        ///
    }
    const checkLogin=()=>{
        let check=localStorage.getItem("token")
        if(check){
            history.push('/select-topic')
        }
    }
    return (
        <div className="signup" onLoad={checkLogin()}>
            {/* <LoginNavigation />  */}
            <NavigationBar />
            <div className="signup-form">
                <h2 className="text-center signup-text p-1 pt-2 mt-2">Sign Up</h2>
                <div className="text-center welcome pb-1 d-none d-sm-block">Create an account</div>
                <div className="text-center pt-2 pb-3">
                    <FacebookLogin
                        appId="902479030555681"
                        autoLoad={false}
                        fields="name,email,picture,first_name,last_name,birthday,gender"
                        callback={responseFacebook}
                        // cssClass="my-facebook-button-class"
                        cssClass="facebook"
                        textButton=""
                        icon={<i className="fab fa-facebook-f" />}
                    />
                    
                    <GoogleLogin
                    clientId="248341868091-8l64p2blghn4dp8ptu1v188voe6eta6i.apps.googleusercontent.com"
                    buttonText=""
                    // Button={<i className="fab fa-facebook" />}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    className="google"
                    icon={<i className="fab fa-facebook" />}
                    cookiePolicy={'single_host_origin'}
                    />
                 </div>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Row>
                    <Form.Group as={Col} lg={6} controlId="first_name">
                        <Form.Control type="text" required pattern="[a-zA-Z0-9]{2,20}$"  placeholder="First Name *" value={firstName} onChange={e=>setFirstName(e.target.value)} />
                        {/* <Form.Label>First Name</Form.Label> */}
                        <Form.Control.Feedback type="invalid">Enter valid Name</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} lg={6} controlId="last_name">
                        <Form.Control required type="text" pattern="[a-zA-Z0-9\s]{2,20}$" placeholder="Last Name *" value={lastName} onChange={e=>setLastName(e.target.value)} />
                        {/* <Form.Label>Last Name</Form.Label> */}
                        <Form.Control.Feedback type="invalid">Enter valid Last name please</Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group as={Col} lg={6} controlId="mobile">
                        <Form.Control type="tel" placeholder="Mobile Number " pattern="[0-9]{10,15}$" value={mobileNumber} onChange={e=>setMobileNumner(e.target.value)} />
                        {/* <Form.Label>Mobile Number</Form.Label> */}
                        {/* <Form.Control.Feedback type="valid">Provide Mobile Number please</Form.Control.Feedback> */}
                    </Form.Group>
                    <Form.Group as={Col} lg={6} controlId="email">
                        <Form.Control type="email" required placeholder="Email address *"  pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" value={email} onChange={e=>setEmail(e.target.value)} />
                        {/* <Form.Label>Email</Form.Label> */}
                        <Form.Control.Feedback type="invalid">Provide valid Email address please</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} lg={6} controlId="gender">
                        <Form.Control as="select" placeholder="Gender *" value={gender}  onChange={e=>setGender(e.target.value)}>
                        <option value="">Gender</option>
                        <option value="male" >Male</option>
                        <option value="female" >Female</option>
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">Provide gender please</Form.Control.Feedback> */}
                    </Form.Group>
                    <Form.Group as={Col} lg={6} className="d-none d-sm-block">

                    </Form.Group>
                    <Form.Group as={Col} lg={6} controlId="password">
                        <InputGroup className="">
                        <Form.Control type={hidepasswordeye?"password":"text"} pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$" required placeholder="Password *" value={password} onChange={e=>setPassword(e.target.value)} />
                        <InputGroup.Append>
                        <InputGroup.Text className="password-eye">{hidepasswordeye?<i className="fas fa-eye" onClick={handlepassword} />:<i className="fas fa-eye-slash" onClick={handlepassword} />}</InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control.Feedback type="invalid">Minimum eight characters, at least one letter, one number and one special character and maximum 20 characters</Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} lg={6} controlId="confirmpassword">
                        <InputGroup className="mb-2">
                        <Form.Control type={hideconfirm?"password":"text"} required pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$" placeholder="Reconfirm Password" value={confirmpassword} onChange={e=>setConfirmPassword(e.target.value)} />
                        <InputGroup.Append>
                        <InputGroup.Text className="password-eye">{hideconfirm?<i className="fas fa-eye" onClick={handleconfirm} />:<i className="fas fa-eye-slash" onClick={handleconfirm} />}</InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control.Feedback type="invalid">Minimum eight characters, at least one letter, one number and one special character and maximum 20 characters</Form.Control.Feedback>
                        </InputGroup>
                        {/* <Form.Label>Reconfirm Password</Form.Label> */}
                    </Form.Group>
                    <div className="signup-bottom">
                        <Col>
                            <Button type="submit" className="signup-button btn-block" >Sign Up</Button>
                        </Col>
                    </div>
                    </Form.Row>
                    {/* <BrowserRouter> */}
                    <div className="policies mt-3 text-center">By signing up using one of the methods above, you agree to our <Link to="/terms-and-condition">Terms of Service</Link> and <Link onClick={()=>history.push("/privacy-policies")} >Privacy Policy.</Link></div>
                    {/* </BrowserRouter>     */}
                </Form>
            </div>
            {/* <Row className="text-center">
            <NavLink exact activeClassName="active_class" to="/sidebar">About</NavLink>
            <NavLink exact activeClassName="active_class" to="/">Contact</NavLink>
            <NavLink exact activeClassName="active_class" to="/topicpage">Home</NavLink>
            </Row> */}
            <Footer />
        </div>
    )
}

export default Signup

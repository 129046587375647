import axios from 'axios'
import {baseurl} from '../../../apiurl'
export const topicFollow=(id)=>{
    return(dispatch=>{
        dispatch(topicFollowRequest())
        let y=localStorage.getItem("token")
        let api=baseurl+'topics/follow'
        axios.post(api,id,{headers:{'Authorization':y}})
        .then(response=>{
            console.log(response)
            dispatch(topicFollowSuccess(response))
        })
        .catch(error=>{
            console.log(error)
            dispatch(topicFollowFailure(error))
        })
    })
}
export const topicFollowRequest=()=>{
    return{
        type:'TOPIC_FOLLOW_REQUEST'
    }
}
export const topicFollowSuccess=(data)=>{
    return{
        type:'TOPIC_FOLLOW_SUCCESS',
        payload:data
    }
}
export const topicFollowFailure=(error)=>{
    return{
        type:'TOPIC_FOLLOW_FAILURE',
        payload:error
    }
}
// Topic unfollow
// export const topicUnfollow=(id)=>{
//     return(dispatch=>{
//         dispatch(topicUnfollowRequest())
//         axios.post('',id)
//         .then(response=>{
//             console.log(response)
//             dispatch(topicUnfollowSuccess(response))
//         })
//         .catch(error=>{
//             console.log(error)
//             dispatch(topicUnfollowFailure(error))
//         })
//     })
// }
// export const topicUnfollowRequest=()=>{
//     return{
//         type:'TOPIC_UNFOLLOW_REQUEST'
//     }
// }
// export const topicUnfollowSuccess=(data)=>{
//     return{
//         type:'TOPIC_UNFOLLOW_SUCCESS',
//         payload:data
//     }
// }
// export const topicUnfollowFailure=(error)=>{
//     return{
//         type:'TOPIC_UNFOLLOW_FAILURE',
//         payload:error
//     }
// }

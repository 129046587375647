import axios from 'axios'
import React, { useState } from 'react'
import { Button, Col, Container, Form } from 'react-bootstrap'
import { useHistory } from 'react-router'
import Swal from 'sweetalert2'
import { baseurl } from '../apiurl'
import Footer from '../container/Footer'
import NavigationBar from '../container/NavigationBar'

const ChangePassword = (props) => {
    console.log(props)
    const history=useHistory()
    const [oldPassword,setOldPassword]=useState("")
    const [newPassword,setNewPassword]=useState("")
    const [confirmPassword,setConfirmPassword]=useState("")
    const [validated, setValidated] = useState(false);
    const validation=()=>{
        if(validated===false){
            return false
        }else if(newPassword!==confirmPassword){
            Swal.fire("Password doesn't match","","warning")
            setValidated(false)
            return false
        }
        return true
    }
    const handleChangePassword=(event)=>{
        event.preventDefault()
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
        }
        let data=new FormData()
        let isValid=validation()
        setValidated(true)
        {props?.location?.state?.is_old_password_exist ?
            data.append("old_password",oldPassword)
            :data.append("old_password",null)}
        data.append("new_password",newPassword)
        if(isValid){
            axios.post(baseurl+"users/update-password",data,{headers:{'Authorization':localStorage.getItem("token")}})
            .then(response=>{
                console.log(response)
                Swal.fire(response.data.message,"","success")
                history.go(-1)
            })
            .catch(error=>{
                console.log(error.response.data.message)
                if(error.response.status===400){
                Swal.fire(error.response.data.message,"","error")
                }
            })
        }
        console.log(oldPassword)
        console.log(newPassword)

    }
    return (
        <div className="changepassword">
            <NavigationBar />
            <div className="changepassword-container">
            <Container className="w-2">
                <Form noValidate validated={validated} onSubmit={handleChangePassword}>
                    <h2 className="text-center pt-5 pb-3">Change password</h2>
                    {
                        props?.location?.state?.is_old_password_exist ?
                        <Form.Group>
                            
                            <Form.Control type="password" placeholder="Old Password" required pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$" value={oldPassword} onChange={e=>setOldPassword(e.target.value)}></Form.Control>
                        </Form.Group>:""
                    }
                    <Form.Group>
                        <Form.Control type="password" placeholder="New Password" required pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$" value={newPassword} onChange={e=>setNewPassword(e.target.value)}></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control type="password" placeholder="Confirm New Password" required pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$" value={confirmPassword} onChange={e=>setConfirmPassword(e.target.value)}></Form.Control>
                    </Form.Group>
                    <Form.Row  className="pt-5">
                        <Col xs={6} sm={6}>
                            <Button className="px-2 btn-block " onClick={()=>history.goBack()}>Cancel</Button>
                        </Col>
                        <Col xs={6} sm={6}>
                            <Button type="submit" className="px-2 btn-block">Change Password</Button>
                        </Col>
                    </Form.Row>
                </Form>
            </Container>
            </div>
            <Footer />
        </div>
    )
}

export default ChangePassword

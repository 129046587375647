import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Container, OverlayTrigger, Popover, Row} from 'react-bootstrap'
import NavigationBar from '../container/NavigationBar'
import { useDispatch, useSelector } from 'react-redux'
import { getQuestion } from '../assets/redux/action/questionAction'
import { getOption } from '../assets/redux/action/optionAction'
// import parse from 'html-react-parser'
import {imageUrl} from './../apiurl'
import Loader from './Loader'
import Hotkeys from 'react-hot-keys';
import Footer from '../container/Footer'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router'
let previousid=[]
const Question2 = (props) => {
    console.log(props)
    const dispatch=useDispatch()
    const history=useHistory()
    const type=props?.location?.state?.playtype
    const ids=props?.location?.state?.id
    const question1 = useSelector(state => state.question1)
    const option = useSelector(state => state.option)
    const [pop,setPop]=useState(false)
    const [show,setShow]=useState(false)
    const [initialtime,setInitialTime]=useState(5)
    const [maintainIndex,setMaintainIndex]=useState()
    let date=new Date()
    let time1
    let time2=new Date()
    useEffect(()=>{
        type ?
        dispatch(getQuestion(type,ids)) :
        dispatch(getQuestion())
    },[dispatch])
    useEffect(()=>{
        time1=date.getTime()
        // initialtime=time1
        setInitialTime(time1)
        console.log("First time",initialtime)

    },[])
    useEffect(()=>{
        
        if(option?.optiondata?.[maintainIndex]?.is_question_done){
            setShow(true)
            let ele=document.getElementsByName('chk');  
            for(let i=0; i<ele.length; i++){  
                if(ele[i].type==='checkbox')  
                    ele[i].disabled=true;      
            }
            // let time2=date.getTime() 
            // console.log("time 2 ",time2) 
            // console.log("Time 1",time1)
            // time3=Math.floor(time2-time1)%1000
            // console.log("Time difference ",time3)  
        }else{
            setShow(false)
        } 
        
        option?.optiondata?.[maintainIndex]?.is_option_correct?setPop(true):setPop(false) 
    },[option,maintainIndex])
    const handleNextQuestion=()=>{
        // alert('next question called')
        option.optiondata=[]
        // setMaintainIndex(5)
        setShow(false)
        // setShow(false)
        // document.getElementById("solution").style.display="none"
        dispatch(getQuestion(type,ids))
        let ele=document.getElementsByName('chk');  
        for(let i=0; i<ele.length; i++){  
            if(ele[i].type==='checkbox')  
                ele[i].checked=false;      
        }  
        previousid=[]        
    }
    const checkboxClass=(index)=>{
        let checked="icon fas "
        // console.log("correct",option.optiondata?.[index]?.is_option_correct?setPop(true):setPop(false))
        if(option?.optiondata?.[index]){
           checked=option?.optiondata?.[index]?.is_option_correct?checked+"text-success bg-white fa-check":checked+'text-danger bg-white fa-times'
        }
        return checked
    }
    const handleOption=(question_id,option_id,index)=>{
        let time=time2.getTime()
        console.log("Time 2 ",time)
        console.log("Time 1",initialtime)
        console.log("Difference",Math.floor(time-initialtime)%1000)
        setMaintainIndex(index)

        if(previousid.includes(option_id)){
            for( var i = 0; i < previousid.length; i++){ 
                if ( previousid[i] === option_id) {
                  previousid.splice(i, 1); 
                  i--;
                }
             }
        }else{
        let obj={"question_id":question_id,"option_id":option_id,"previous_selected_options":previousid,"time_used":Math.floor(time-initialtime)%1000,"play_type":question1?.questiondata?.play_type}
        dispatch(getOption(obj,index))
        previousid.push(option_id)    
        document.getElementById(option_id).disabled=true    
        }
    }
    const handleQuit=()=>{
        Swal.fire({
            title:"Are you sure you want to quit the Quiz ?",
            // showCancelButton:true,
            showDenyButton:true,
            confirmButtonText: `Quit`,
            denyButtonText: `Don't Quit`
        })
        .then((result)=>{
            if (result.isConfirmed) {
                Swal.fire('Quitting the Quiz',
                'You can choose another topic to proceed with and enjoy the learning', 'success')
                history.push('/select-topic')
              } else if (result.isDenied) {
                Swal.fire('Want to proceed with the quiz', '', 'info')
              }
        })
    }
    const getText = htmlString => new DOMParser().parseFromString(htmlString, 'text/html').body.textContent;
    return (
        <div className="question-page">
            <NavigationBar />
            <div className="container-content">
                <Container>
                    <Row>
                        <Col xs={9} className="topic-name-header">
                            Topic : {question1?.questiondata?.topic_name}
                        </Col>
                        <Col className="text-right" >
                            <label onClick={()=>handleQuit()}>Quit</label>
                        </Col>
                    </Row>
                </Container>
                <Container className="quest">
                    <Row className="question-title">
                        { getText(question1?.questiondata?.title+"Hemf,ddddffffffffffffffffffffffddddddddddddddddddddddddkkkkvvvvvvvvvmjjjjjjjjjjjjjjjjjjjjjjiiippppppppppppppppppppppppjjjjjjjjjjjjjjjjjjjjjjjjjjkkkkkkkkkkkkkkkkkkkkkkkkkkknnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnl")}
                    </Row>
                    <div className="question2-image">
                    {
                        question1?.questiondata?.image ?
                    
                    <img  className=" p-2" id="img"  src={(imageUrl+question1?.questiondata?.image) || ""} alt="question-pic"   />
                    :""}
                    </div>
                    <Row className="question2-answers">
                        <Col>
                            {
                                question1?.questiondata?.options?.map((item,index)=>
                                <OverlayTrigger trigger="focus" placement="right" overlay={
                                    <Popover className={` back ${pop?'bg-success':'bg-danger'}`}>
                                        <Popover.Content>
                                            {pop ?
                                                "Yeah! \You got it right!":
                                                "Oops! \Give it another try"
                                            }
                                        </Popover.Content>
                                    </Popover>
                                }>
                                    <div className={`checkbo ${show?"check":"checkbox"}`} key={item.id} >
                                
                                <div className="pretty p-icon p-smooth" data-tip="the Option is wrong" data-for="soclose">
                                    <input type="checkbox" name="chk" onClick={()=>{handleOption(question1.questiondata.id,item.id,index)}} />
                                    <div className="state">
                                        <i id={item.id} data-for="soclose" className={checkboxClass(index)} ></i>
                                        {/* <i  className={`icon fas ${option?.optiondata?.data?.d?.is_option_correct ? 'fa-check-square' : 'fa-times'} `} /> */}
                                        <label className="parse">{getText(item.title)}</label>
                                    </div>
                                </div>
                                </div>
                                </OverlayTrigger>
                                )
                            }
                        </Col>
                        <Col  className={` answer-ne ${show?'showdiv':'hide'}`}>
                       
                                { getText(option?.optiondata?.[0]?.feedback || "")}
                                { getText(option?.optiondata?.[1]?.feedback || "")}
                                { getText(option?.optiondata?.[2]?.feedback || "")}
                                { getText(option?.optiondata?.[3]?.feedback || "")}
                            
                    </Col>
                    </Row>
                    <div className={` next-arrow ${show?'enabled-div':'disabled-div'} `}>
                            <i className="fas fa-chevron-circle-right" onClick={()=>handleNextQuestion()}   />
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Question2

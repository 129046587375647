const initialState={
    loading:false,
    optiondata:[],
    error:""
}
const saveOptionData=(optiondata,payload)=>{
    // const {optiondata,payload}=props
    console.log("SaveOptiondata ",optiondata)
    console.log("save optioin payload",payload)

    for(let i=0;i<=payload.index;i++){
        if(!optiondata?.[payload.index]){
            optiondata[payload.index]={}
        }
    }
    optiondata[payload.index]=payload.data
    return optiondata
}
const optionReducer=(state=initialState,action)=>{
    switch(action.type){
        case 'GET_OPTION_REQUEST':
            return{
                ...state,
                loading:true
            }
        case 'GET_OPTION_SUCCESS':
            return{
                ...state,
                loading:false,
                optiondata:saveOptionData(state.optiondata,action.payload),
                error:""
            }
        case 'GET_OPTION_FAILURE':
            return{
                ...state,
                loading:false,
                optiondata:"",
                error:action.payload
            }
        
        default :
            return state
    }
}
export default optionReducer

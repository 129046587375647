import axios from 'axios'
import {baseurl} from '../../../apiurl'
export const getOption=(obj,index)=>{
    return(dispatch=>{
        dispatch(getOptionRequest())
        let y=localStorage.getItem('token')
        let api=baseurl+'attempt/attempt-question'
        axios.post(api,obj,{headers:{'Authorization':y}})
        .then(response=>{
            console.log("option feedback",response)
            dispatch(getOptionSuccess(response.data.data,index))
        })
        .catch(error=>{
            dispatch(getOptionFailure(error.message))
        })
    })
}
export const getOptionRequest=()=>{
    return{
        type:'GET_OPTION_REQUEST'
    }
}
export const getOptionSuccess=(data,index)=>{
    return{
        type:'GET_OPTION_SUCCESS',
        payload:{data,index}
    }
}
export const getOptionFailure=(error)=>{
    return{
        type:'GET_OPTION_FAILURE',
        payload:error
    }
}
const initialState={
    loading:false,
    userdata:[],
    updateuser:[],
    error:""
}
const userProfileReducer=(state=initialState,action)=>{
    switch(action.type){
        case 'GET_USER_REQUEST':
            return{
                ...state,
                loading:true
            }
        case 'GET_USER_SUCCESS':
            return{
                ...state,
                loading:false,
                userdata:action.payload,
                error:""
            }
        case 'GET_USER_FAILURE':
            return{
                ...state,
                loading:true,
                userdata:"",
                error:action.payload
            }
        case 'GET_SUBTOPIC_REQUEST':
            return{
                ...state,
                loading:true
            }
        case 'GET_SUBTOPIC_SUCCESS':
            return{
                ...state,
                loading:false,
                subtopicdetail:action.payload,
                error:""
            }
        case 'GET_SUBTOPIC_FAILURE':
            return{
                ...state,
                loading:false,
                subtopicdetail:[],
                error:action.payload
            }
        default :
            return state
    }
}
export default userProfileReducer

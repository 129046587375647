import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, InputGroup } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { baseurl } from '../apiurl'
import LoginNavigation from '../container/LoginNavigation'
import NavigationBar from '../container/NavigationBar'

const ConfirmPassword = () => {
    const [validated, setValidated] = useState(false);
    const [password,setPassword]=useState("")
    const [confirmPassword,setConfirmPassword]=useState("")
    const [hideconfirm,setHideConfirm]=useState(true)
    const [hidepassword,setHidePassword]=useState(true)
    const history=useHistory()
    const [forgotPassword,showForgotPassword]=useState(true)
    console.log(window.location.href)
    console.log(window.location.href.split("="))
    let id=window.location.href.split("=")
    console.log(id[1])
    const resetid={"id":id[1]}
    const resetpassword={"id":id[1],"password":password}
    useEffect(()=>{
        axios.post(baseurl+'users/check-reset-password-link',resetid)
            .then(response=>{
                console.log(response)
            })
            .catch(error=>{
                console.log(error.response)
                showForgotPassword(false)
                // Swal.fire({
                //     title:''
                // })
                // history.push('/login')
            })

    },[resetid])
    const handlePassword=(props)=>{
        hidepassword?setHidePassword(false):setHidePassword(true)
    }
    const handleconfirm=(props)=>{
        hideconfirm?setHideConfirm(false):setHideConfirm(true)
    }
    const validation=()=>{
        if(password!==confirmPassword){
            Swal.fire("Password not matched","","error")
            return false;
        }else if(password.length<8){
            Swal.fire("Not a valid password","",'warning')
        }else{
            return true;
        }
    }
    
    const changePassword=(event)=>{
        event.preventDefault()
        const form = event.currentTarget
    if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
    }
        setValidated(true)
        // history.push('/login')
        let isValid=validation()
        if(isValid){
            // axios.post(baseurl+'users/check-reset-password-link',resetid)
            // .then(response=>{
            //     console.log(response)
            //     if(response.status===200){
            //         Swal.fire(response.data.message,"","success")
            //         axios.post(baseurl+'users/reset-customer-password',resetpassword)
            //         .then(response=>{
            //             console.log(response)
            //             if(response.status===200){
            //                 Swal.fire(response.data.message,"","success")
            //             }
            //         })
            //         .catch(error=>{
            //             console.log("update password error",error.response)
            //             if(error.reponse.status===403){
            //                 Swal.fire(error.response.data.message,"","error")
            //             }
            //         })
            //     }
            // })
            // .catch(error=>{
            //     console.log(error.response.status===403)
            //     if(error.response.status===403){
            //         Swal.fire(error.response.data.message,"","error")
            //     }
            // })
                axios.post(baseurl+'users/reset-customer-password',resetpassword)
                    .then(response=>{
                        console.log(response)
                        if(response.status===200){
                            Swal.fire(response.data.message,"","success")
                            history.push('/login')
                        }
                    })
                    .catch(error=>{
                        console.log("update password error",error.response)
                        if(error.reponse.status===403){
                            Swal.fire(error.response.data.message,"","error")
                        }
                    })
        }

    }
    return (
        <div className="forgot-password">
        {/* <LoginNavigation /> */}
        <NavigationBar />
        <div className={`password-form password-link ${forgotPassword?"showerror":'showform'}`} >
            Your link is expired
        </div>
        <div /*className="password-form"*/ className={`password-form ${forgotPassword?"showform":'showerror'}`} >
            <h2 className="text-center pt-1 pb-5">Change Password</h2>
            <Form noValidate validated={validated} onSubmit={changePassword}>
                <Form.Group controlId="password">
                    {/* <Form.Label>New Password</Form.Label> */}
                    <InputGroup className="mb-2">
                    <Form.Control type={hidepassword?"password":"text"} required pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$" value={password} placeholder="Password" onChange={e=>setPassword(e.target.value)} />
                    <InputGroup.Append>
                    <InputGroup.Text className="password-eye">{hidepassword?<i className="fas fa-eye" onClick={handlePassword} />:<i className="fas fa-eye-slash" onClick={handlePassword} />}</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">Minimum eight characters, at least one letter, one number and one special character and maximum 20 characters</Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="confirm">
                    {/* <Form.Label>Confirm Password</Form.Label> */}
                    <InputGroup className="mb-2">
                    <Form.Control type={hideconfirm?"password":"text"} required pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$" value={confirmPassword} placeholder="Password" onChange={e=>setConfirmPassword(e.target.value)} />
                    <InputGroup.Append>
                    <InputGroup.Text className="password-eye">{hideconfirm?<i className="fas fa-eye" onClick={handleconfirm} />:<i className="fas fa-eye-slash" onClick={handleconfirm} />}</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">Minimum eight characters, at least one letter, one number and one special character and maximum 20 characters</Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="button" >
                    <Col className="text-center" >
                        <Button type="submit" className="change-password">Change password</Button>
                    </Col>
                </Form.Group>
            </Form>
            </div>
            
        </div>
    )
}

export default ConfirmPassword

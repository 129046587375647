import React, { useEffect, useState } from 'react'
import { Button, Col, Form, FormGroup, Modal } from 'react-bootstrap'
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getPlay } from '../assets/redux/action/playTypeAction';
import { topicFollow } from '../assets/redux/action/topicFollowingAction';
import { getTopicList } from '../assets/redux/action/topicListAction';
import NavigationBar from '../container/NavigationBar'

const SelectedTopic = () => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const history=useHistory()
    const list = useSelector(state => state.list)
    console.log(list)
    useEffect(()=>{
        dispatch(getTopicList())
    },[dispatch])
    const handleClose = () =>{
        setShow(false);
        history.push('/question')
    } 
    const handleShow = () => setShow(true);
    const handleRandom=()=>{
        let obj={"option":"random"}
        dispatch(getPlay(obj))
    }
    const handleRevision=()=>{
        let obj={"option":"revision"}
        dispatch(getPlay(obj))
    }
    let c=[]
    c=list?.topiclist?.data?.followed_topics.map(item=>item.id)
    const topicFollowing=(id)=>{
        if(c.length<3){
        c.push(id)
        document.getElementById("f"+id).style.display="none"
        document.getElementById("u"+id).style.display="inline-block"
        let ids={"topics":c}
        dispatch(topicFollow(ids))
        // window.location.reload()
        }else{
            Swal.fire({
                title:"Maximum topics selected can be Three!",
                icon:'warning'
            })
        }
    }
    const topicUnfollowing=(id)=>{
        for( var i = 0; i < c.length; i++){ 
            if ( c[i] === id) {
              c.splice(i, 1); 
              i--;
            }
         }
        document.getElementById("f"+id).style.display="inline-block"
        document.getElementById("u"+id).style.display="none"
        let ids={"topics":c}
        dispatch(topicFollow(ids))
        // window.location.reload()
    }
    return (
        <div className="selected">
            <NavigationBar />
            
            <div className="shadow selected-topic-section">
                <div className="p-2">Selected Topics</div>
                {/* <label className="selected-top">Contour</label>
                <label className="selected-top">Consulting</label>
                <label className="selected-top">Magazine</label>
                <label className="selected-top">Food</label>
                <label className="selected-top">Technology</label>
                <label className="selected-top">Restraunts</label> */}
                {
                    list?.topiclist?.data?.topics.map(item=>
                    <label key={item.id} className="selected-top">
                        {/* <div> */}
                        <label id={"f"+item.id} className="followed" onClick={()=>topicFollowing(item.id)}>{item.name}</label>
                        <label id={"u"+item.id} className="unfollowed" style={{display:"none"}} onClick={()=>topicUnfollowing(item.id)} >{item.name}</label>
                        {/* </div> */}
                    </label>
                    )}
                {
                    list?.topiclist?.data?.followed_topics.map(item=>
                        <label key={item.id} className="unselected-top">
                        <label id={"f"+item.id} className="followed" style={{display:"none"}} onClick={()=>topicFollowing(item.id)}>{item.name}</label>
                        <label id={"u"+item.id} className="unfollowed" onClick={()=>topicUnfollowing(item.id)} >{item.name}</label>
                    </label>
                        )
                }   
            <div className="p-1 pt-4">
                <Col>
                    <Button className="browse-button btn-block">Browse All Topics</Button>
                </Col>
            </div>
            </div>
            <div className="p-3 select-bottom-button" >
                <Col >
                    <Button className="btn-block bottom-button" onClick={handleShow}>Play</Button>
                </Col>
            </div>
            <Modal show={show} onHide={handleClose} centered backdrop="static" className="m-2">
        <Modal.Header closeButton>
          <Modal.Title>Selected Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form className="pl-3">
                <FormGroup>
                    <FormCheckInput type="radio" name="rad1"id="opt1" onClick={()=>handleRandom()} />
                    <FormCheckLabel for="opt1">Random</FormCheckLabel>
                    <p>New question from all category selected will be included</p>
                    <FormCheckInput type="radio" name="rad1"id="opt2" onClick={()=>handleRevision()} />
                    <FormCheckLabel for="opt2">Revision</FormCheckLabel>
                    <p>Only questions which you answered wrong in the previous sessions will be included</p>
                </FormGroup>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <div className="text-center">
            <Button type="submit" onClick={handleClose}>
                Continue
            </Button>
            </div>
        </Modal.Footer>
      </Modal>
           
        </div>
    )
}

export default SelectedTopic

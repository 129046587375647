import axios from 'axios'
import React, { useState } from 'react'
import { Button, Col, Form } from 'react-bootstrap'
// import { useHistory } from 'react-router'
import Swal from 'sweetalert2'
// import { useHistory } from 'react-router-dom'
import { baseurl } from '../apiurl'
import Footer from '../container/Footer'
import LoginNavigation from '../container/LoginNavigation'
import NavigationBar from '../container/NavigationBar'

const ForgotPassword = (e) => {
    // const history=useHistory()    
    const [email,setEmail]=useState("")
    // const [code,setCode]=useState("")
    // const history=useHistory()
    // const [hideconfirm,setHideConfirm]=useState(true)
    // const [password,setPassword]=useState("")
    // const [confirmpassword,setConfirmPassword]=useState("")
    // const [hidepasswordeye,setPasswordeye]=useState(true)
    // const handleconfirm=(props)=>{
    //     hideconfirm?setHideConfirm(false):setHideConfirm(true)
    // }
    // const handlepassword=(props)=>{
    //     hidepasswordeye?setPasswordeye(false):setPasswordeye(true)
    // }
    let data=new FormData()
    data.append("email",email)
    const handleForgotPassword = (event) => {
        event.preventDefault()
        
        axios.post(baseurl+'users/send-forgot-password-mail',data)
        .then(response=>{
            console.log(response.status)
            if(response.status===200){
            Swal.fire(response.data.message,'','success')
            // history.push('/reset-password')
            }
        })
        .catch(error=>{
            console.log(error.response.status)
            if(error.response.status===404){
            Swal.fire(error.response.data.message,"","error")
            }
        })
        // console.log(email)
        
    }
    return (
        <div className="forgot-password">
            {/* <LoginNavigation /> */}
            <NavigationBar />
            <div className="password-form">
                <h2 className="text-center pt-5 pb-2 forgot-password-heading">Forgot password</h2>
                <p className="recovery-link-text">We will send a password recovery link to your email ID</p>
                <Form onSubmit={handleForgotPassword}>
                    <Form.Group controlId="email">
                        {/* <Form.Label className="p-2">Email</Form.Label> */}
                        <Form.Control type="email"  placeholder="Email" required pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" value={email} onChange={e=>setEmail(e.target.value)} />
                        {/* <Form.Control.Feedback type="invalid">Please enter valid email address</Form.Control.Feedback> */}
                    </Form.Group>
                    
                    <Form.Group controlId="button">
                        <Col  className="text-center">
                            <Button type="submit" className="forgot-button" >Send Mail</Button>
                        </Col>
                    </Form.Group>
                </Form>
                <div  className="text-center">
                    <Button  variant="link" className="signin-button" >Sign In</Button>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ForgotPassword

import axios from 'axios'
import {baseurl} from '../../../apiurl'
export const getTopicDetail=(id)=>{
    return(dispatch=>{
        dispatch(getTopicDetailRequest())
        let y=localStorage.getItem('token')
        let api=baseurl+'topics/topic-detail'
        axios.get(api,{headers:{'Authorization':y}})
        .then(response=>{
            console.log("topics action",response.data.data)
            const topicdetail=response.data.data
            dispatch(getTopicDetailSuccess(topicdetail))
        })
        .catch(error=>{
            dispatch(getTopicDetailFailure(error.message))
        })
        
    })
}
export const getTopicDetailRequest=()=>{
    return{
        type:'GET_TOPIC_DETAIL_REQUEST'
    }
}
export const getTopicDetailSuccess=(data)=>{
    return{
        type:'GET_TOPIC_DETAIL_SUCCESS',
        payload:data
    }
}
export const getTopicDetailFailure=(error)=>{
    return{
        type:'GET_TOPIC_DETAIL_FAILURE',
        payload:error
    }
}
import axios from 'axios'
import {baseurl} from '../../../apiurl'
export const getQuestion=(type,ids)=>{
    let id=ids
    if(ids===undefined){
        id=[]
    }
    if(type===undefined){
        type=""
    }
    console.log("sssssssssssss",[id])
    return(dispatch=>{
        dispatch(getQuestionRequest())
        let y=localStorage.getItem('token')
        let api
        type ?
        api=baseurl+'question/play-question?play_type='+type+"&sub_topic_list=["+id+"]" :
        api=baseurl+'question/play-question'
        axios.get(api,{headers:{'Authorization':y}})
        .then(response=>{
            dispatch(getQuestionSuccess(response.data.data))
        })
        .catch(error=>{
            dispatch(getQuestionFailure(error.message))
        })
    })
}
export const getQuestionRequest=()=>{
    return{
        type:'GET_QUESTION_REQUEST'
    }
}
export const getQuestionSuccess=(data)=>{
    return{
        type:'GET_QUESTION_SUCCESS',
        payload:data
    }
}
export const getQuestionFailure=(error)=>{
    return{
        type:'GET_QUESTION_FAILURE',
        payload:error
    }
}

export const resetQuestionData = () => {
    return{
        type:'RESET_QUESTION_DATA',
    }
}
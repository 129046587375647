import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router'

const Footer = () => {
    const history=useHistory()
    return (
        <div className="footer">
            <Row className="footer-row">
                {/* <Col className=""> */}
                    <Col xs={12} lg={2} className="icon" onClick={()=>history.push('/terms-and-condition')}>Terms & Condition</Col>
                    <Col xs={12} lg={2} className="icon" onClick={()=>history.push('/privacy-policies')}>Privacy Policy</Col>
                {/* </Col> */}
                <Col xs={12} lg={{offset:6,span:2}} style={{float:"right"}}> All Rights Reserved</Col>
            </Row>
        </div>
    )
}

export default Footer

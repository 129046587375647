import React from 'react'
import { Form } from 'react-bootstrap'
import NavigationBar from '../../container/NavigationBar'

const CreateQuiz = () => {
    function datedata(){

    }
    return (
        <div>
            <NavigationBar />
            <div className="create-quiz-form">
                    <div className="text-center">Create Quiz</div>
                <Form >
                    <Form.Group>
                        <Form.Label>Quiz Name</Form.Label>
                        <Form.Control id="quiz-name" type="date"></Form.Control>
                    </Form.Group>
                </Form>
            </div>
            <div >
                
            </div>
        </div>
    )
}

export default CreateQuiz

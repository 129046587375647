import {combineReducers} from 'redux'
import topicsReducer from './topicsReducer'
import topicListReducer from './topicListReducer'
import userProfileReducer from './userProfileReducer'
import playTypeReducer from './playTypeReducer'
import questionReducer from './questionReducer'
import optionReducer from './optionReducer'
import topicDetailReducer from './topicDetailReducer'
const allReducers=combineReducers({
    topics:topicsReducer,
    list:topicListReducer,
    userdetail:userProfileReducer,
    playdetail:playTypeReducer,
    question1:questionReducer,
    option:optionReducer,
    detail:topicDetailReducer
    
})
export default allReducers
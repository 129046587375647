import React, { useEffect } from 'react'
import {  useDispatch, useSelector } from 'react-redux'
import { Col, Row, Container } from 'react-bootstrap'
import { getTopicList } from '../assets/redux/action/topicListAction'
import { useHistory } from 'react-router-dom'
import NavigationBar from '../container/NavigationBar'
import Footer from '../container/Footer'
import Loader from './Loader'
// let c=[]
const SelectTopic = () => {
    const history=useHistory()
    const dispatch = useDispatch()
    
    const list = useSelector(state => state.list)
    useEffect(()=>{
        dispatch(getTopicList())
    },[dispatch])
    console.log(list)
    return list.loading ?(
        <Loader />
        // <h2>Loading</h2>
        ) : list.error ? (
          <h2>{list.error}</h2>
        ) : (
        <div className="topic-page" >
            <NavigationBar />
            <div className="topic-section">
                <Container>
                <p className="text-center select-topic-heading mb-4"><span>Pick a topic you want to start quiz with</span></p>
                { list?.topiclist ?
                    <Row className="mx-1 my-3">
                        {
                            list?.topiclist?.map(item=>
                        <Col sm={6} md={6} xs={12} className="mt-2 px-1" key={item.id} id={item.id}>
                            <div className="topic" style={{background:item.color_code}} onClick={()=>history.push({pathname:'/topic-detail/'+item.slug,state:{slug:item.slug}})}>
                            <div className="topic-name-percentage mt-1 "><p className="mb-1">{item.name}</p><p className="mb-1">{item.percentage}{item.percentage?"%":""}</p></div>
                                <span className="">{item.short_description}</span>
                            </div>
                        </Col>
                            )}
                    </Row>
                    : <div className="text-center">Let Admin add Topics to play quiz with</div>
                }
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default SelectTopic

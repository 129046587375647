import React, { useState } from 'react'
import GoogleLogin from 'react-google-login'
import FacebookLogin from 'react-facebook-login'
import { Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import axios from 'axios'
import {baseurl} from '../apiurl'
import SignupNavigation from '../container/SignupNavigation'
import Footer from '../container/Footer'
import NavigationBar from '../container/NavigationBar'
import Loader from './Loader'
const Login = (props) => {
    console.log(props)
    const [validated, setValidated] = useState(false);
    const [email,setEmail]=useState("")
    const [password,setPassword]=useState("")
    const [hideconfirm,setHideConfirm]=useState(true)
    const [showLoader,setShowLoader]=useState(false)
    const history=useHistory()
    // console.log(history)
    // console.log(history.go(-1))
    let data=new FormData()
    const handleconfirm=(props)=>{
        hideconfirm?setHideConfirm(false):setHideConfirm(true)
    }
    const responseFacebook = (response) => {
        // alert('ff')
        // setShowLoader(true)
        // console.log(response);
        data.append("id_token",response.accessToken)
        data.append("login_type","FACEBOOK")
        axios.post(baseurl+'users/social-login',data)
        .then(response=>{
            // setShowLoader(false)
            console.log("login response",response)
            localStorage.setItem("token",response.data.data.token)
            response.data.data.is_topic_selected ?
            history.push('/play/'+localStorage.getItem("topic")):
            history.push('/select-topic')
        })
        .catch(error=>{
            // setShowLoader(false)
            console.log(error)
            // Swal.fire({
            //     title:'Something went wrong try again',
            //     icon:'warning'
            // })
        })
      }
    const responseGoogle = (response) => {
        // setShowLoader(true)
        // Nprogress.start()
        let data1=new FormData()
        // console.log(response);
        data1.append("id_token",response.tokenId)
        data1.append("login_type","GOOGLE")
        axios.post(baseurl+'users/social-login',data1)
        .then(response=>{
            // setShowLoader(false)
            console.log("login google response",response)
            localStorage.setItem("token",response.data.data.token)
            response.data.data.is_topic_selected ?
            history.push('/play/'+localStorage.getItem("topic")):
            history.push('/select-topic')
            // history.push('/select-topic')
        })
        .catch(error=>{
            // setShowLoader(false)
            console.log(error)
            // Swal.fire({
            //     title:'SomeThing went wrong try again',
            //     icon:'warning'
            // })
        })
        
      }
    const validation=()=>{
        if(password.length<8){
            return false;
        }else{
            return true
        }
    }
    const handleLogin=(event)=>{
        event.preventDefault()
        const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
        setValidated(true)
        data.append("email",email)
        data.append("password",password)
        console.log("email",email)
        console.log("password",password)
        let isValid=validation()
        if(isValid){
        axios.post(baseurl+'users/login',data)
        .then(response=>{
            console.log("login response",response)
            localStorage.setItem("token",response.data.data.token)
            // is_topic_selected stored in database so that if user hit login url after login and 
            // a case in which he had not yet attempted any question yet
            localStorage.setItem("is_topic_selected",response.data.data.is_topic_selected)
            // console.log("props",props.location.state.link)
            if(props.location.state===undefined){
                response.data.data.is_topic_selected ?
                history.push({pathname:'/play/'+localStorage.getItem("topic"),
                        state:{afterLongtime:"true"}
            }):
                history.push('/select-topic')
            }else{
                history.go(-1)
            }
            // props?.location?.state?.link ?
            // // history.go(-1):
            // console.log("histtory(-1)"):
            // response.data.data.is_topic_selected ?
            // history.push({pathname:'/play/'+localStorage.getItem("topic"),
            //             state:{afterLongtime:"true"}
            // console.log("histrory('/play')")
            // :
            // console.log("history('/select-tic)")
            // history.push('/select-topic')
        })
        .catch(error=>{
            console.log(error.response)
            Swal.fire({
                title:'Wrong Credentials',
                icon:'warning'
            })
        })
        }
    }
    const checkLogin=()=>{
        let check=localStorage.getItem("token")
        if(check){
            localStorage.getItem("is_topic_selected")===true?
            history.push('/play/'+localStorage.getItem("topic")):
            history.push('/select-topic')
            
        }
    }
    // if(showLoader) return <Loader />
    // else
    return (
        <div className="login" onLoad={checkLogin()}>
            {/* <SignupNavigation /> */}
            <NavigationBar />
            <div className="login-form">
                <h2 className="text-center login-text">Log In</h2>
                <p className="pb-2 welcome text-center">Welcome back</p>
                <Form noValidate validated={validated} onSubmit={handleLogin}>
                    <Form.Group controlId="email">
                        <Form.Control type="email" required placeholder="Email"  pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" value={email} onChange={e=>setEmail(e.target.value)} />
                        {/* <Form.Label>Email</Form.Label> */}
                        <Form.Control.Feedback type="invalid">Provide valid Email address please</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="password">
                        <InputGroup className="mb-2">
                        <Form.Control type={hideconfirm?"password":"text"} required pattern="[A-Za-z0-9._@#$!]{8,20}$" placeholder="Password" value={password} onChange={e=>setPassword(e.target.value)} />
                        {/* <Form.Label>Email</Form.Label> */}
                        <InputGroup.Append>
                        <InputGroup.Text className="password-eye">{hideconfirm?<i className="fas fa-eye" style={{background:"none"}} onClick={handleconfirm} />:<i className="fas fa-eye-slash" onClick={handleconfirm} />}</InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control.Feedback type="invalid">Provide password please</Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="login" as={Row} className="pt-2">
                        <Col xs={6} sm={6}>
                            <Button type="submit" className="login-button btn-lg btn-block">Log in</Button>
                        </Col>
                        <Col className="text-right">
                            <Button variant="link" className="login-forgot-button" onClick={()=>history.push('/forgot-password')} >Forgot Password ?</Button>
                        </Col>
                    </Form.Group>
                </Form>
                <div className="text-center">
                    {/* <div className="d-inline-block " onClick={()=>setShowLoader(true)}> */}
                    <FacebookLogin
                        appId="902479030555681"
                        autoLoad={false}
                        fields="name,email,picture,first_name,last_name,birthday,gender"
                        callback={responseFacebook}
                        // cssClass="my-facebook-button-class"
                        cssClass="facebook"
                        textButton=""
                        icon={<i className="fab fa-facebook-f" />}
                    />
                    {/* </div> */}
                    {/* <div className="d-inline-block " > */}
                    <GoogleLogin
                    clientId="248341868091-8l64p2blghn4dp8ptu1v188voe6eta6i.apps.googleusercontent.com"
                    buttonText=""
                    // Button={<i className="fab fa-facebook" />}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    className="google"
                    icon={<i className="fab fa-google" />}
                    cookiePolicy={'single_host_origin'}
                    />
                    {/* </div> */}
                 </div>
                 <div className="text-center pt-3 account">Don't have an account?</div>
                 <div className="text-center login-signup-link"><Button variant="link" onClick={()=>history.push("/signup")}>Sign up</Button></div>
            </div>
            <Footer />
        </div>
    )
}

export default Login

import axios from 'axios'
import {baseurl} from '../../../apiurl'
export const getUser=()=>{
    return(dispatch=>{
        dispatch(getUserRequest())
        let y=localStorage.getItem('token')
        let api=baseurl+'users/user-profile'
        // console.log("apissssss",api)
        axios.get(api,{headers:{'Authorization':y}})
        .then(response=>{
            // console.log("topics action",response.data.data)
            const topicdetail=response.data
            dispatch(getUserSuccess(topicdetail))
        })
        .catch(error=>{
            dispatch(getUserFailure(error.message))
        })
    })
}
export const getUserRequest=()=>{
    return{
        type:'GET_USER_REQUEST'
    }
}
export const getUserSuccess=(data)=>{
    return{
        type:'GET_USER_SUCCESS',
        payload:data
    }
}
export const getUserFailure=(error)=>{
    return{
        type:'GET_USER_FAILURE',
        payload:error
    }
}
import React, { useEffect } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getUser } from '../assets/redux/action/userProfileAction'
import Footer from '../container/Footer'
import NavigationBar from '../container/NavigationBar'

const UserProfile = () => {
    const history=useHistory()
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getUser())
    },[dispatch])
    const userdetail = useSelector(state => state.userdetail)
    console.log(userdetail)
    let firstname=userdetail?.userdata?.data?.first_name
    let lastname=userdetail?.userdata?.data?.last_name
    let email=userdetail?.userdata?.data?.email
    let gender=userdetail?.userdata?.data?.gender===null?"":userdetail?.userdata?.data?.gender
    let contact=userdetail?.userdata?.data?.mobile_no=== null?"":userdetail?.userdata?.data?.mobile_no
    // alert(contact)
const checkLogin=()=>{
    let check=localStorage.getItem("token")
    if(check){

    }else{
        history.push("/")
    }
}
    return (
        <div className="user" onLoad={checkLogin()}>
        <NavigationBar />
            <div className="user-detail">
            <h2 className="text-center pt-5">User Profile</h2>
            <div className="pt-2 pb-5 p-3">
            <div className="pb-2"><Row><Col className="text- tr" xs={4} >First Name: </Col><Col className="text-left tl">{firstname}</Col></Row></div>
            <div className="pb-2"><Row><Col className="text- tr" xs={4} >Last Name: </Col><Col className="tl">{lastname}</Col></Row></div>
            <div className="pb-2"><Row><Col className="text- tr" xs={4} >Email: </Col><Col className="tl">{email}</Col></Row></div>
            <div className="pb-2"><Row><Col className="text- tr" xs={4} >Gender: </Col><Col className="tl"> {gender}</Col></Row></div>
            <div className="pb-2"><Row><Col className="text- tr" xs={4} >Contact: </Col><Col className="tl"> {contact}</Col></Row></div>
            </div>
            {/* <div className="text-center pb-4">
                <Button className="edit-button btn-block" onClick={()=>history.push({pathname:'/edit-profile',state:{first_name:firstname,last_name:lastname,email:email,gender:gender,mobile:contact}})} >edit</Button>
            </div> */}
            <Container className="pb-4">
                <Row>
                    <Col xs={6} sm={6} className="px-1">
                        <Button className="edit-butto btn-block" onClick={()=>history.push({pathname:'/edit-profile',state:{first_name:firstname,last_name:lastname,email:email,gender:gender,mobile:contact}})} >Edit profile</Button>
                    </Col>
                    <Col xs={6} sm={6} className="px-1">
                        <Button className="btn-block  " onClick={()=>history.push({pathname:'/change-password',state:{is_old_password_exist:userdetail?.userdata?.data?.is_password_exist}})}>Change password</Button>
                    </Col>
                </Row>
            </Container>
            </div>
            {/* <div className="user-detail">
                <h2 className="text-center pt-5">User Profile</h2>
                <div className="pt-2 pb-1 p-3">
                    <Row className="pl-3 pb-2"><Col className="text- tr" xs={4} lg={3}>First Name: </Col><Col className="text-left tl">{firstname}</Col></Row>
                    <Row className="pl-3 pb-2"><Col className="text- tr" xs={4} lg={3}>Last Name: </Col><Col className="text-left tl">{lastname}</Col></Row>
                    <Row className="pl-3 pb-2"><Col className="text- tr" xs={4} lg={3}>Email: </Col><Col className="text-left tl">{email}</Col></Row>
                    <Row className="pl-3 pb-2"><Col className="text- tr" xs={4} lg={3}>Gender: </Col><Col className="text-left tl">{gender}</Col></Row>
                    <Row className="pl-3 pb-2"><Col className="text- tr" xs={4} lg={3}>Contact: </Col><Col className="text-left tl">{contact}</Col></Row>
                    <div>First Name : {firstname}</div>
                    <div>Last Name : {lastname}</div>
                    <div>Email : {email}</div>
                    <div>Gender : {gender}</div>
                    <div>Contact : {contact}</div>
                </div>
                <div className="text-center pb-4">
                    <Button className="edit-button btn-block" onClick={()=>history.push({pathname:'/edit-profile',state:{first_name:firstname,last_name:lastname,email:email,gender:gender,mobile:contact}})} >edit</Button>
                </div>
            </div> */}
            <Footer />
        </div>
    )
}

export default UserProfile

import axios from 'axios'
import { baseurl } from '../../../apiurl'
export const getTopic=(slug)=>{
    return(dispatch=>{
        dispatch(getTopicRequest())
        let y=localStorage.getItem('token')
        // let y='Token '+x
        let f=slug
        let api
        
            localStorage.getItem("token")?
            api=baseurl+'topics/topic-detail/'+f :
            api=baseurl+'topics/open-topic-detail/'+f 
            // console.log(api)        
        axios.get(api,{headers:{'Authorization':y}})
        .then(response=>{
            console.log("topics action",response.data.data)
            const topicdetail=response.data.data
            dispatch(getTopicSuccess(topicdetail))
        })
        .catch(error=>{
            dispatch(getTopicFailure(error.message))
        })
        
    })
}
export const getTopicRequest=()=>{
    return{
        type:'GET_TOPIC_REQUEST'
    }
}
export const getTopicSuccess=(data)=>{
    return{
        type:'GET_TOPIC_SUCCESS',
        payload:data
    }
}
export const getTopicFailure=(error)=>{
    return{
        type:'GET_TOPIC_FAILURE',
        payload:error
    }
}
export const getSubTopic=(id)=>{
    return(dispatch=>{
        dispatch(getSubTopicRequest())
        let x=localStorage.getItem('token')
        let f=id
        let y=x
        let api=baseurl+'topics/subtopic-detail/'+f
        axios.get(api,{headers:{'Authorization':y}})
        .then(response=>{
            console.log("subtopic",response)
            const subtopicdetail=response.data.data
            dispatch(getSubTopicSuccess(subtopicdetail))
        })
        .catch(error=>{
            dispatch(getSubTopicFailure(error.message))
        })
        
    })
}
export const getSubTopicRequest=()=>{
    return{
        type:'GET_SUBTOPIC_REQUEST'
    }
}
export const getSubTopicSuccess=(data)=>{
    return{
        type:'GET_SUBTOPIC_SUCCESS',
        payload:data
    }
}
export const getSubTopicFailure=(error)=>{
    return{
        type:'GET_SUBTOPIC_FAILURE',
        payload:error
    }
}
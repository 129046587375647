import React, { useEffect } from 'react'
import { Button, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { topicFollow } from '../assets/redux/action/topicFollowingAction'
import { getSubTopic } from '../assets/redux/action/topicsAction'
import NavigationBar from '../container/NavigationBar'
import ReactHtmlParser from 'react-html-parser';
import Loader from './Loader'
import Footer from '../container/Footer'
const Subtopic = (props) => {
    // console.log(props)
    // const slug=props.location.state.slug
    // const topicname=props.location.state.name
    // const topic_id=props.location.state.topicid
    const dispatch=useDispatch()
    const history=useHistory()
    useEffect(()=>{
        dispatch(getSubTopic(window.location.href.split("/")[4]))
    },[dispatch])
    const topics = useSelector(state => state.topics)
    console.log("subtopics details",topics)
    // const handlePlay=(id)=>{
    //     let ids={"subtopics":[id],"topic_id":topic_id}
    //     dispatch(topicFollow(ids))
    //     // saving subtopics name to localstorage so that when user redirects directly 
    //     // from home we have a track which subtopic previously he was attending
    //     localStorage.setItem("topic",topics.subtopicdetail.name)
    //     history.push({
    //         pathname:'/play/'+topics.subtopicdetail.name,
    //         state:{playtype:"random",id:id}
    //     })
    // }
    return topics.loading ?(
        <Loader />
        // <h2>Loading</h2>
        ) : topics.error ? (
          <h2>{topics.error}</h2>
        ) : (
        <div className="subtopic">

            <NavigationBar />
            <div className="topic-page-header subtopic-header">
                <div className="subtopic-heads">
                <div>
                    <i className="fas fa-arrow-left icon" onClick={()=>history.goBack()} />
                </div>
                <p className="subtopic-parent">{topics?.subtopicdetail?.topic_name}</p>
                </div>
                <p className="subtopic-heading">{topics?.subtopicdetail?.name}</p>
                <div className="mt-3 subtopic-description">
                    { ReactHtmlParser(topics?.subtopicdetail?.description)}
                </div>
                {/* <div className="button-groups pt-4">
                    <Col className="pb-2" >
                        <Button className="btn-block play-button" onClick={()=>handlePlay(topics?.subtopicdetail?.id)}>play</Button>
                    </Col>
                    
            </div> */}
            </div>
            
            <div className="subtopic-footer">
            <Footer />
            </div>
        </div>
    )
}

export default Subtopic

import React, { useEffect } from 'react'
import {  Col, Row, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getTopicList } from '../assets/redux/action/topicListAction'
// import LoginNavigation from '../container/LoginNavigation'
import architect from '../assets/images/architect.png'
import Footer from '../container/Footer'
import NavigationBar from '../container/NavigationBar'
const Home = () => {
    const history=useHistory()
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getTopicList())
    },[dispatch])
    const list = useSelector(state => state.list)
    const checkLogin=()=>{
        let check=localStorage.getItem("token")
        if(check){
            history.push('/play/'+localStorage.getItem("topic"))
        }
    }
    console.log(list)
    return (
        <div className="home"/* onLoad={checkLogin()}*/ >
            {/* <LoginNavigation /> */}
            <NavigationBar />
            <div className="banner-section">
            <Container className="home-header">
            <Row className="">
                <Col lg={6} className="">
                    <div>Welcome to TreadRiser</div>
                    <p className='mt-2'><strong>TreadRiser</strong> is a tool developed to help your architectural carrier. It helps in Knowledge
                        gain and long retention. The Architect Registration Examination (ARE) is like a long
                        journey.<strong> TreadRiser </strong> makes that journey more enjoyable and easier for you.
                        <strong> TreadRiser </strong> uses a targeted approach to increase your chances of passing.  </p>
                </Col>
                <Col lg={6}>
                    <img src={architect} alt="architect" />
                </Col>
            </Row>
            </Container>
            </div>
            {/* Home page content */}
            <div className="home-section">
            <div className="text-center mb-4">
                <h3 className="home-heading">GET STARTED</h3>
                <p>Test Your Knowledge On The Following Topics</p>
            </div>
            <Container className="home-secion">
                {/* <p className="text-center select-topic-heading"><span>Pick a topic you want to start quiz with</span></p> */}
                <Row fluid className="mx-1n">
                    {
                        list?.topiclist?.map(item=>
                    <Col sm={6} xs={12} className="mt-2 px-1" key={item.id} id={item.id}>
                        <div className="topic" style={{background:item.color_code}} onClick={()=>history.push({pathname:'/topic-detail/'+item.slug,state:{slug:item.slug}})}>
                            <p className="topic-name mt-2 mb-1">{item.name} <i className="far fa-arrow-alt-circle-right arrow-icon" ></i></p>
                            <span>{item.short_description}</span>
                        </div>
                    </Col>
                        )}
                </Row>
            </Container>
            </div>
            {/* <div className="logo-div">
                <img src={logo} className="logo" alt="TreadRiser logo" />
                <div className="text-center pt-2 pb-2"><b>TreadRiser - Quiz</b></div>
                <div className="home-bottom">
                    <Button variant="link" onClick={()=>history.push('/login')}>Login</Button>
                    <Button variant="link" onClick={()=>history.push('/signup')}>Sign up</Button>
                </div>
            </div> */}
            <Footer />
        </div>
    )
}

export default Home

import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Container, OverlayTrigger, Popover, Row, Spinner} from 'react-bootstrap'
import NavigationBar from '../container/NavigationBar'
import { useDispatch, useSelector } from 'react-redux'
import { getQuestion, resetQuestionData } from '../assets/redux/action/questionAction'
import { getOption } from '../assets/redux/action/optionAction'
import parse from 'html-react-parser'
import ReactHtmlParser from 'react-html-parser'
import {baseurl, imageUrl} from './../apiurl'
import Loader from './Loader'
import Hotkeys from 'react-hot-keys';
import Footer from '../container/Footer'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router'
import axios from 'axios'
let previousid=[]
const Question = (props) => {
    // console.log("Question page",props)
    const dispatch=useDispatch()
    const history=useHistory()
    const [show,setShow]=useState(false)
    const [initialtime,setInitialTime]=useState(5)
    const [maintainIndex,setMaintainIndex]=useState()
    // let show=false
    const question1 = useSelector(state => state.question1)
    const option = useSelector(state => state.option)
    const loading = useSelector(state => state.loading)
    const type=props?.location?.state?.playtype || question1?.questiondata?.play_type
    const ids=props?.location?.state?.id || question1?.questiondata?.sub_topic_list
    const [pop,setPop]=useState(false)
    const [alertBox,setAlertBox]=useState(false)
    let date=new Date()
    let time1
    let time2=new Date()
    // let time3 //difference of time taken
    const [output,setOutput]=useState("Hey , I am a component that listen to keyup and key down")
    useEffect(()=>{
        {/* Alert box if all random questions are done */}        
            if(question1?.questiondata?.is_question_found===false){
            // console.log():
            Swal.fire({
                title: "Congratulations !",
                text :"You have finished all the Quiz questions !!",
                icon:"success",
                showDenyButton: true,
                // showCancelButton: true,
                confirmButtonText: `Topics page`,
                denyButtonText: `Play quiz again`,
                }).then((result) => {
                if (result.isConfirmed) {
                    history.push("/select-topic")
                } else if (result.isDenied) {
                    let y=localStorage.getItem('token')
                    // let api=baseurl+'attempt/attempt-question'
                    let obj ={play_type:type,sub_topic_list:ids}
                    axios.post(baseurl+"question/reset-question-attempts",obj,{headers:{'Authorization':y}})
                    .then(response=>{
                        response.status===200?
                        // (type ?
                        dispatch(getQuestion(type,ids)) :dispatch(getQuestion())
                        // dispatch(getQuestion())):""
                    }).catch(error=>{
                        console.log(error.response)
                    })
                }
                })
            }
    },[question1.questiondata])
    useEffect(() => {
        return () => {
          dispatch(resetQuestionData())
        }
      }, [])
    useEffect(()=>{
        if(option?.optiondata?.[maintainIndex]?.is_question_done){
            setShow(true)
            let ele=document.getElementsByName('chk');  
            for(let i=0; i<ele.length; i++){  
                if(ele[i].type==='checkbox')  
                    ele[i].disabled=true;      
            }
        }else{
            setShow(false)
        } 
        // option?.optiondata?.[maintainIndex]?.is_option_correct?setPop(true):setPop(false) 
    },[option,maintainIndex])
    ////disable the right click
    // useEffect(()=>{
    //     // dispatch(getQuestion())
    //     document.addEventListener('contextmenu', (e) => {
    //         e.preventDefault();
    //       });
    // })
    //question api
    useEffect(()=>{
        type ?
        dispatch(getQuestion(type,ids)) :
        dispatch(getQuestion())
    },[dispatch])
    // getting time of appearing question
    
    useEffect(()=>{
        time1=date.getTime()
        // initialtime=time1
        setInitialTime(time1)
        // console.log("First time",initialtime)

    },[])
    useEffect(()=>{
        if(props?.location?.state?.afterLongtime===undefined){
            // alert("false")
        }else{
            // alert("true")
            setAlertBox(true)
        }
    },[])
    const handleNextQuestion=()=>{
        // alert('next question called')
        option.optiondata=[]
        // setMaintainIndex(5)
        setShow(false)
        // setShow(false)
        // document.getElementById("solution").style.display="none"
        dispatch(getQuestion(type,ids))
        let ele=document.getElementsByName('chk');  
        for(let i=0; i<ele.length; i++){  
            if(ele[i].type==='checkbox')  
                ele[i].checked=false;      
        }  
        previousid=[]        
    }
    // time3=Math.floor(time2-time1)%1000
    // console.log("Difference between time",time3)
    const handleOption=(question_id,option_id,index)=>{
        let time=time2.getTime()
        // console.log("Time 2 ",time)
        // console.log("Time 1",initialtime)
        // console.log("Difference",Math.floor(time-initialtime)%1000)
        // alert("before M")
        setMaintainIndex(index)
        // alert("after M")
        if(previousid.includes(option_id)){
            for( var i = 0; i < previousid.length; i++){ 
                if ( previousid[i] === option_id) {
                  previousid.splice(i, 1); 
                  i--;
                }
             }
        }else{
        let obj={"question_id":question_id,"option_id":option_id,"previous_selected_options":previousid,"time_used":Math.floor(time-initialtime)%1000,"play_type":question1?.questiondata?.play_type}
        dispatch(getOption(obj,index))
        previousid.push(option_id)    
        document.getElementById(option_id).disabled=true    
        }
    }
    
    // console.log("option",option)
    // console.log(question1?.questiondata?.data?.data)    
    const getText = htmlString => new DOMParser().parseFromString(htmlString, 'text/html').body.textContent;
    const checkboxClass=(index)=>{
        let checked="icon fas "
        // console.log("correct",option.optiondata?.[index]?.is_option_correct?setPop(true):setPop(false))
        if(option?.optiondata?.[index]){
           checked=option?.optiondata?.[index]?.is_option_correct?checked+"text-success bg-white fa-check":checked+'text-danger bg-white fa-times'
        }
        return checked
    }
    console.log(question1)
    // console.log("option",option.optiondata)
    const onKeyUp=(keyName, e, handle)=> {
        // console.log("test:onKeyUp", e, handle)
        // console.log("Handle",handle)
        // this.setState({
        //   output: `onKeyUp ${keyName}`,
        // });
        setOutput( `onKeyUp ${keyName}`)
      }
      const onKeyDown=(keyName, e, handle)=> {
        console.log("test:onKeyDown", keyName, e, handle)
        setOutput(`onKeyDown ${keyName}`)
        // alert("ctrl+enter called")
        handleNextQuestion()
        // this.setState({
        //   output: `onKeyDown ${keyName}`,
        // });
      }
    const handleQuit=()=>{
        Swal.fire({
            title:"Are you sure you want to quit the Quiz ?",
            // showCancelButton:true,
            showDenyButton:true,
            confirmButtonText: `Quit`,
            denyButtonText: `Don't Quit`
        })
        .then((result)=>{
            if (result.isConfirmed) {
                Swal.fire('Quitting the Quiz',
                'You can choose another topic to proceed with and enjoy the learning', 'success')
                history.push('/select-topic')
              } else if (result.isDenied) {
                Swal.fire('Want to proceed with the quiz', '', 'info')
              }
        })
    }
    const checkLogin=()=>{
        let check=localStorage.getItem("token")
        if(check){

        }else{
            history.push('/')
        }
    }
    return question1.loading ?(
        <Loader />
        ) : question1.error ? (
          <h2>{question1.error}</h2>
        ) : (
        <div className="question-page" onLoad={checkLogin()}>
            <NavigationBar /> 
            <Hotkeys keyName="ctrl+enter,alt+s" onKeyUp={onKeyUp} onKeyDown={onKeyDown}>
                
            </Hotkeys>
            
            <div className="container-content">
            <Container className="p-0">
                <Alert  className={` alert-div px-2 ${alertBox?'alert-div-visible':'alert-div-hide'}`} onClose={() => setAlertBox(false)} dismissible>
                Welcome Back! This is where you left us! Resume your quiz or <u>QUIT</u>
                </Alert>
            </Container>
                <Container className="pt-3 mt-3">
                    <Row >
                        <Col xs={9} className="topic-name-header p-0 pt-2">
                            Topic : <span>{question1?.questiondata?.topic_name}</span>
                        </Col>
                        <Col className="text-right quit p-0" >
                            <Button variant="link" className="text-dark" onClick={()=>handleQuit()}><u>Quit</u></Button>
                        </Col>
                    </Row>
                </Container>
                <Container className="quest pb-5">
                {
                    question1.questiondata &&
                    <label className="play-type-label">{question1?.questiondata?.play_type?.toUpperCase()}</label>
                }
                    <Row className="question-title d-block  px-3 ">
                    
                        { ReactHtmlParser(question1?.questiondata?.title)}
                        {
                            question1.questiondata &&
                            <label className="pl-1">(Select {question1?.questiondata?.total_correct_options} options)</label>
                        }
                    </Row>
                    <div className="question2-image">
                    {
                        question1?.questiondata?.image ?
                    
                    <img  className="" id="img"  src={(imageUrl+question1?.questiondata?.image) || ""} alt="question-pic"   />
                    :""}
                    </div>
                    <Row className="question2-answers mt-2">
                        <Col className="options-new ">
                            {
                                question1?.questiondata?.options?.map((item,index)=>
                                <OverlayTrigger trigger="focus" placement="right" overlay={
                                    <Popover className={` back ${option?.optiondata?.[index] ? option?.optiondata?.[index]?.is_option_correct ?'bg-success':'bg-danger' : ""}`}>
                                        <Popover.Content>
                                            {option?.optiondata?.[index] ? option?.optiondata?.[index]?.is_option_correct ?
                                                "Yeah! \You got it right!":
                                                "Oops! \Give it another try" : <Spinner animation="border" />
                                            }
                                        </Popover.Content>
                                    </Popover>
                                }>
                                <div for="chk" className={`checkbo icon  ${show?"check":"checkbox"}`} key={item.id}  >

                                <div className="pretty p-icon p-smooth" data-tip="the Option is wrong" data-for="soclose">
                                    <input type="checkbox" name="chk" id="chk"   onClick={()=>handleOption(question1.questiondata.id,item.id,index)} />
                                    <div className="state">
                                        <i id={item.id} data-for="soclose" className={option?.optiondata?.[index] ? option?.optiondata?.[index]?.is_option_correct?"icon fas text-success bg-white fa-check":'icon fas text-danger bg-white fa-times' : ''} ></i>
                                        {/* <i  className={`icon fas ${option?.optiondata?.data?.d?.is_option_correct ? 'fa-check-square' : 'fa-times'} `} /> */}
                                        <label className="parse">{ReactHtmlParser(item.title)}</label>
                                    </div>
                                </div>
                                </div>
                                </OverlayTrigger>
                                )
                            }
                        </Col>
                        <Col xs={12} sm={6} className={` answer-new ${show?'showdiv':'hide'}`}>
                       
                                { ReactHtmlParser(option?.optiondata?.[0]?.feedback || "")}
                                { ReactHtmlParser(option?.optiondata?.[1]?.feedback || "")}
                                { ReactHtmlParser(option?.optiondata?.[2]?.feedback || "")}
                                { ReactHtmlParser(option?.optiondata?.[3]?.feedback || "")}
                                { ReactHtmlParser(option?.optiondata?.[4]?.feedback || "")}
                                { ReactHtmlParser(option?.optiondata?.[5]?.feedback || "")}
                                { ReactHtmlParser(option?.optiondata?.[6]?.feedback || "")}
                                { ReactHtmlParser(option?.optiondata?.[7]?.feedback || "")}
                            
                    </Col>
                    </Row>
                    <div className={` next-arrow ${show?'enabled-div':'disabled-div'} `}>
                            <i className="fas fa-chevron-circle-right" onClick={()=>handleNextQuestion()}   />
                    </div>
                </Container>
                
            </div>
        {/* {question1?.questiondata?.title ? 
            <Row className="new-row mt-5">
                <div className="questionpage-topics-name-heading mb-3">
                    <div><span>Topic : </span>{question1.questiondata?.topic_name}</div>
                    <div className="quit icon" onClick={()=>handleQuit()}>Quit</div>
                </div>
                <div className="question-parent-box">
                    <div className="question-box">
                        <label className="play-type-label">{question1?.questiondata?.play_type.toUpperCase()}</label>
                        <div className="question-new mt-4">
                            {   
                                question1?.questiondata?.title ?
                                getText(question1?.questiondata?.title || "All question of this section are attempted"):
                                <div className="if-no-question">All questions attempted</div>
                            }
                        </div>
                    </div>
                    
                    <div className={` next-arrow ${show?'enabled-div':'disabled-div'} `}>
                            <i className="fas fa-chevron-circle-right" onClick={()=>handleNextQuestion()}   />
                        </div>
                    <Row>
                    {
                        question1?.questiondata?.image ?
                    
                    <img  className="question-image p-2" id="img"  src={(imageUrl+question1?.questiondata?.image) || ""} alt="question-pic"   />
                    :" "}
                    <Col  className="options-new">
                    {
                        question1?.questiondata?.options?.map((item,index)=> 
                        <OverlayTrigger id="123" className="123" delay={{ show: 250, hide: 400 }} trigger="focus" placement="right" overlay={
                            <Popover className={` back ${pop?'bg-success':'bg-danger'}`}>
                                <Popover.Content>
                                    {pop?"Yeah! You got it right!":"Oopss! Give it another try"}
                                </Popover.Content>
                            </Popover>
                        } >
                            <div className={`checkbo ${show?"check":"checkbox"}`} key={item.id} >
                                
                                <div className="pretty p-icon p-smooth" data-tip="the Option is wrong" data-for="soclose">
                                    <input type="checkbox" name="chk" onClick={()=>{handleOption(question1.questiondata.id,item.id,index)}} />
                                    <div className="state">
                                        <i id={item.id} data-for="soclose" className={checkboxClass(index)} ></i>
                                        <label className="parse">{getText(item.title)}</label>
                                    </div>
                                </div>
                            </div>
                            
                            </OverlayTrigger>
                        )
                    }
                    </Col>
                    <Col  className={` answer-new ${show?'showdiv':'hide'}`}>
                        <div id="solution" className={` ${show?'showdiv':'hide' }`} >
                            <div className="answer-header d-none d-sm-block text-center text-white">
                                Correct
                            </div>
                            <div id="answer-sol" className="answer-section">
                                { getText(option?.optiondata?.[0]?.feedback || "")}
                                { getText(option?.optiondata?.[1]?.feedback || "")}
                                { getText(option?.optiondata?.[2]?.feedback || "")}
                                { getText(option?.optiondata?.[3]?.feedback || "")}
                            </div>
                        </div>
                    </Col>
                            
                    </Row>
                    </div>
            </Row>
            
            :
            <div className="no-question-block">
                All Questions available are attempted please go to topics and select other Topics
                
            </div>
                } */}
                {/* <div className={`question-next  next-arrow ${show?'enabled-div':'disabled-div'} `}>
                            <i className="fas fa-chevron-circle-right" onClick={()=>handleNextQuestion()}   />
                </div> */}
            

            
            {/* <Row className="question-row">      
            <Col xs={12} lg={12} className="ques">
            <div className={`div-center ${show?"border-show":"border-hide"}  `}>
            <div className="question" >
                <div className="question-section ">
                    <div className="questionpage-topics-name-heading mb-3">
                        <div><span>Topic : </span>{question1.questiondata?.topic_name}</div>
                        <div className="quit" onClick={()=>handleQuit()}>Quit</div>
                    </div> */}
                    {/*---------------- question ---------------------*/}
                {/* <div className="question-text">   
                    {
                        // parse(question1?.questiondata?.title || "All questions of topics you follow are done in this section")
                        getText(question1?.questiondata?.title || "All questions of topics you follow are done in this section")
                    }
                    (<i> select {question1?.questiondata?.total_correct_options} options </i>) 

                </div>
                    {
                        question1?.questiondata?.image ?
                    
                    <img  className="question-image p-2" id="img"  src={(imageUrl+question1?.questiondata?.image) || ""} alt="question-pic"   />
                    :" "} */}
                    {/*--------------- options of the question----------------- */}
                    {/* {
                        // shuffled?.map((item,index)=>
                        question1?.questiondata?.options?.map((item,index)=> 
                            <div className={`checkbo ${show?"check":"checkbox"}`} key={item.id} >

                                <div className="pretty p-icon p-smooth">
                                    <input type="checkbox" name="chk" onClick={()=>{handleOption(question1.questiondata.id,item.id,index)}} />
                                    <div className="state">
                                        <i id={item.id} className={checkboxClass(index)} ></i> */}
                                        {/* <i  className={`icon fas ${option?.optiondata?.data?.d?.is_option_correct ? 'fa-check-square' : 'fa-times'} `} /> */}
                                        {/* <label className="parse">{getText(item.title)}</label>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    This question has {question1?.questiondata?.total_correct_options} answers
                </div>
            </div>
            </div> */}
            {/* { option?.optiondata?.[0]?.is_question_done ? */}
                {/* <div id="solution" className={`answer ${show?'showdiv':'hide' }`} >
                        <div className="answer-header d-none d-sm-block text-center text-white">
                            Correct
                        </div>
                        <div id="answer-sol" className="answer-section">
                            { getText(option?.optiondata?.[0]?.feedback || "")}
                            { getText(option?.optiondata?.[1]?.feedback || "")}
                            { getText(option?.optiondata?.[2]?.feedback || "")}
                            { getText(option?.optiondata?.[3]?.feedback || "")}
                        </div>
                </div>
                <div className={` next-arrow ${show?'enabled-div':'disabled-div'} `}>
                <i class="fas fa-chevron-circle-right" onClick={()=>alert("Arrow called")}   />
                </div> */}
                {/* } */}
                
                
             
            
            {/* <div id="1" style={{display:"none"}} className="answer">
                        <div className="answer-header text-center text-white d-none d-sm-block">
                            Correct
                        </div>
                        <div id="2" className="answer-section"></div>
                </div> */}

            {/* </Col>
            
            <Col lg={6}>
                <div id="1" style={{display:"none"}} className="answer">
                        <div >
                            
                        </div>
                </div>
                
            </Col>
            </Row> */}
            {/* {
                option?.optiondata?.is_question_done ? */}
            {/* {
                show &&
            
            <div id="next-button" className="text-center pt-5 ">
                <Button className="next-question" onClick={()=>handleNextQuestion()}>Next Question</Button>
            </div>
            } */}
            {/* } */}
            <Footer />
        </div>
    )
}

export default Question

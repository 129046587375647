import React from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router'
import Footer from './Footer'
import NavigationBar from './NavigationBar'

const NotFound = ({location}) => {
    const history=useHistory()
    return (
        <div className="page-not-found">
            <NavigationBar />
            <div className="not-found mt-5 p-3">
                <h2>Sorry This path {location.pathname} is unavailable</h2>
                <div className="">Please go to the <Button variant="link" onClick={()=>history.push('/')}>Home Page</Button> or Try Clicking <Button variant="link" onClick={()=>history.goBack()}>Back</Button> Button</div>
            </div>

            {/* <h2>No match Found {location.pathname}</h2> */}
            <Footer />
        </div>
    )
}

export default NotFound

import React, { useEffect, useState } from 'react'
import NavigationBar from '../container/NavigationBar'
import { Button, Col, Row } from 'react-bootstrap'
import {  useDispatch, useSelector } from 'react-redux'
import {  getTopic } from '../assets/redux/action/topicsAction'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { topicFollow } from '../assets/redux/action/topicFollowingAction'
import ReactHtmlParser from 'react-html-parser';
import Loader from './Loader'
import Footer from '../container/Footer'
import Swal from 'sweetalert2'
let previousid=[]
const TopicPage = (props) => {
    console.log("props",props)
    const history=useHistory()
    const dispatch = useDispatch()
    const topics = useSelector(state => state.topics)
    let isFounded
    //Conditional class variable of revision button
    const [display,setDisplay]=useState(false)
    // let display=useState(false)
    let slug=props?.location?.state?.slug
    let topic_name=useParams()
    console.log(topic_name)
    // console.log(window.location.href)
    // console.log(window.location.href.split("/")[4])
    useEffect(()=>{
        dispatch(getTopic(window.location.href.split("/")[4]))
        // dispatch(getSubTopic(2))
    },[dispatch,slug])
    // let previousid=topics?.topicdetail?.topic_detail?.subtopic?.map(item=>item.id)
    // if(previousid?.length>3){
    //     for(let i=0;i<(previousid?.length-3);i++){
    //         previousid.pop()
    //     }
    // }
    // let previousid=[]
    // useEffect(()=>{
    //     previousid=topics?.topicdetail?.followed_subtopics?.map(id=>id)
    //     alert(previousid)
    // },[])
    // previousid=topics?.topicdetail?.followed_subtopics?.map(id=>id)
    console.log(previousid)
    const handlePlayButton=()=>{
            console.log(previousid)   
            console.log(topics.topicdetail.topic_detail.id)
            if(localStorage.getItem("token")){
                let ids={"subtopics":previousid,"topic_id":topics.topicdetail.topic_detail.id}
                if(previousid.length>0){
                    dispatch(topicFollow(ids))
                    // We save the Topics name in localStorage so that if we redirect him from home 
                    // we have a track of its previous topics history on which he was attempting
                    localStorage.setItem('topic',topics.topicdetail.topic_detail.slug)
                    history.push({
                        pathname:'/play/'+topics.topicdetail.topic_detail.slug,
                        // state:{playtype:"random",id:previousid}
                        state:{playtype:"random",id:previousid,}

                    })
                }else{
                    Swal.fire("Please select at least one topic")
                }
            }else{
                history.push({
                    pathname:'/login',
                    state:{link:'topic-detail/'+slug}
                })
            }
        }
    //function that run when we click any checkbox
    const FollowSubtopic=(id)=>{
        if(previousid.includes(id)){
            for( var i = 0; i < previousid.length; i++){ 
                if ( previousid[i] === id) {
                previousid.splice(i, 1); 
                i--;
                }
            }
        }else{
            previousid.push(id);
        }
        // check(previousid)
        
        // topics.topicdetail.revision_subtopics.map(id=>{
        //     alert(id)
        //     document.getElementById(id).checked?setDisplay(true):setDisplay(false)
        //     }
        //     ) 
        //     console.log("Display",display)
        console.log(previousid)
        // return display
        // topics?.topicdetail?.revision_subtopics.map(id=>
        //     previousid.includes(id)
        //     ) ?
        //     setDisplay(true):setDisplay(false)
        // console.log("previousid",previousid)

        }
        const checkRevision=(tickedid)=>{
            JSON.parse(localStorage.getItem("revision")).some( ai => previousid.includes(ai) )?
            setDisplay(true):setDisplay(false)
            // JSON.parse(localStorage.getItem("revision")).map(id=>
            //     {
            //         alert(id)
            //     document.getElementById(id).checked?setDisplay(true):setDisplay(false)
            // })
        }
    // useEffect(()=>{
    //     if(previousid?.length===0){
    //     previousid=topics?.topicsdetail?.topic_detail?.subtopic.map(id=>id)
    //     var items = document.getElementsByName('followcheck')
    //         for (var i = 0; i < items.length; i++) {
    //             if (items[i].type == 'checkbox')
    //                 items[i].checked = true
    //         }
    //     }
    // },[])
    //function that run as page load
    // useEffect(()=>{
    //     // if(topics?.topicdetail?.followed_subtopics?.length===0){
    //     //     // alert("Jgir")
    //     //     topics?.topicdetail?.topic_detail?.subtopic.map(item=>
    //     //         {alert(item.id)
    //     //             document.getElementById(item.id).checked=true
    //     //         }
    //     //         )
    //     // }
    // },[])
    const handleFollowed=()=>{
        previousid=topics?.topicdetail?.followed_subtopics?.map(id=>id)
        // first time all subtopics bydefault checked
        if(topics?.topicdetail?.followed_subtopics?.length===0){
            topics?.topicdetail?.topic_detail?.subtopic.map(item=>
                {
                    previousid.push(item.id)
                    document.getElementById(item.id).checked=true
                }
                )
        }else{
        topics?.topicdetail?.followed_subtopics?.map(id=>
            document.getElementById(id).checked=true
            )
        }
        //first time check whether revision is followed or not and make that visible
        topics.topicdetail.revision_subtopics.map(id=>{
            // alert(id)
            document.getElementById(id).checked?setDisplay(true):setDisplay(false)
            }
        )
        //store the revision array in localStorage
        localStorage.setItem("revision",JSON.stringify(topics.topicdetail.revision_subtopics))
        console.log("Followed display",display)

    }

    // const check=(previousid)=>{
    //     alert(previousid)
    //     isFounded = topics?.topicdetail?.revision_subtopics?.some( ai => previousid.includes(ai) )
    //         alert(isFounded)
    //         isFounded===true ? setDisplay(true) :setDisplay(false)
    // }
    console.log(display)
    // function when revision button hits
    const handleRevision=()=>{
        let idss={"subtopics":previousid,"topic_id":topics.topicdetail.topic_detail.id}
            dispatch(topicFollow(idss))
        history.push({
            pathname:'/play/'+topics.topicdetail.topic_detail.slug,
            // state:{playtype:"random",id:previousid}
            state:{playtype:"revision",id:previousid,}

        })
    }
    // useEffect(()=>{
    //     topics.topicdetail.revision_subtopics.map(item=>
    //         item
    //         )
    // },[])
    const revisionClass=()=>{
        let revisionclass="btn-block bottom-button"
        // alert("Hey")
        // for(var i=0;i<previousid?.length;i++){
        //     alert("HHHHHHHHHhh")
        //     console.log("revision array",topics?.topicdetail?.revision_subtopics)
        //     if(topics?.topicdetail?.revision_subtopics.includes(previousid)){
        //         alert("includes")
        //         revisionclass=revisionclass+"enableddiv"
        //     }
        // }
        //     // if(previousid.includes)
        // let isFounded = topics?.topicdetail?.revision_subtopics?.some( ai => previousid.includes(ai) )
        //     if(isFounded){
        //         alert("true")
        //         setDisplaying(true)
        //     }else{
        //         alert("false")
        //         setDisplaying(false)
        //     }
        return revisionclass
    }
    console.log("preeeeeeeeeeeevious id",previousid)
    console.log("topics",topics?.topicdetail)
    // console.log("subtopics followed",topics?.topicdetail?.followed_subtopics?.map(id=>id))
    return topics.loading ?(
        <Loader />
        // <h2>Loading</h2>
        ) : topics.error ? (
          <h2>{topics.error}</h2>
        ) : (
        <div className="topicpage" onLoad={()=>handleFollowed()}>
            <NavigationBar />
            <div className="topic-page-header">
                <div className="p-3 text-cente">
                    {/* <img src={image} alt="trophy" height="40" width="40" /> */}
                </div>
                        <div className="p-3 ml-1 topic-content">
                        <h6 className="topicpage-topic-heading pb-2">{topics?.topicdetail?.topic_detail?.name}<span>{topics?.topicdetail?.percentage}{topics.topicdetail.percentage?"%":""}</span></h6>
                        <p className="topicpage-description">
                        { ReactHtmlParser(topics?.topicdetail?.topic_detail?.description) }
                        </p>
                        </div>
                        <div className="p-3 topicpage-subtopics">
                            <Row>
                            
                                <Col className="topicpage-subtopic-heading mr-n4" sm={2} >Sub-Topics</Col>
                                <Col lg={8} xs={12}>
                                <span className=" font-italic helping-text">(You can remove the sub topics if not prepared)</span>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col xs={12} lg={1.5} className="topicpage-subtopic-heading " >Sub-Topics</Col>
                                <Col >( You can remove the sub topicsif not prepared )</Col>
                            </Row> */}
                            {
                                topics?.topicdetail?.topic_detail?.subtopic?.map(item=>
                                    <label key={item.id} className="subtopic-label-section">
                                    <label className="custom-checkbox">
                                    <label className="check" >
                                        <input type="checkbox" name="followcheck" id={item.id} onClick={()=>{FollowSubtopic(item.id);checkRevision(item.id)}} />
                                        <span className="checkmarks"  ></span>
                                    </label>
                                    </label>
                                    <label className=" subtopic-label icon" onClick={()=>history.push({pathname:"/subtopic-detail/"+item.slug,state:{slug:item.slug,name:topics?.topicdetail?.topic_detail.name,topicid:topics?.topicdetail?.topic_detail?.id}})} >{item.name}</label>  
                                    </label>  
                                )
                            }
                        </div>
                    <div className="p-3 topic-button" >
                        <Col>
                        <Button className="btn-block bottom-button" onClick={()=>handlePlayButton()}>START THE QUIZ</Button>
                            <p className="text-center helping-text"><i>( Start the new quiz  )</i></p>
                        </Col>
                        {/* Revision button */}
                        <Col >
                            {console.log("Is founnede",isFounded)}
                            <Button onClick={()=>handleRevision()} className={`btn-block bottom-button ${display ? 'enabled-revision' : 'disabled-revision'} `} >Revision</Button>
                            <p className="text-center helping-text"><i>(Re-attempt the wrong questions again)</i></p>
                        </Col>
                    </div>
            </div>
            {/* <div className="p-3 topic-button" >
                    <Col>
                        <Button className="btn-block bottom-button" onClick={()=>handlePlayButton()}>START THE QUIZ</Button>
                        <p className="text-center"><i>( Start the new quiz  )</i></p>
                    </Col>
                </div> */}
                <Footer />
        </div>
    )
}

export default TopicPage

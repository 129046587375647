import React, { useEffect } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { getTopicDetail } from '../assets/redux/action/topicDetailAction'
import { topicFollow } from '../assets/redux/action/topicFollowingAction'
// import { getTopicList } from '../assets/redux/action/topicListAction'
import { getTopic } from '../assets/redux/action/topicsAction'
import NavigationBar from '../container/NavigationBar'
import Loader from './Loader'

const TopicTab = () => {
    const history=useHistory()
    const dispatch = useDispatch()
    useEffect(()=>{
        // dispatch(getTopicList())
        dispatch(getTopic())
        dispatch(getTopicDetail())
    },[dispatch])
    const detail = useSelector(state => state.detail)
    console.log("details",detail)
    
    // useEffect(()=>{
    //     // topics?.topicdetail?.followed_subtopics?.map(id=>
    //     //     document.getElementById(id).checked=true
    //     //     )
    // })
    
    const topics=useSelector(state=>state.topics)
    console.log("topics",topics)
    // const list = useSelector(state => state.list)
    let previousid=[]
    previousid=detail?.topicdetail?.followed_subtopics?.map(id=>id)
    const FollowSubtopic=(id)=>{
            // console.log("chhhhhhhhhhhh",!document.getElementById(id).checked)
            if(previousid.includes(id)){
            for( var i = 0; i < previousid.length; i++){ 
                if ( previousid[i] === id) {
                  previousid.splice(i, 1); 
                  i--;
                }
             }
        }else if(previousid.length<3){
            previousid.push(id);
        }else{
            Swal.fire({
                title:"You cannot select more than three topic",
                icon:"warning"
            })
            
            document.getElementById(id).checked=false
        }
        console.log("previousid",previousid)
    }
    const handleSaveButton=()=>{
        let ids={"subtopics":previousid}
        dispatch(topicFollow(ids))
        history.push('/play')
    }
    const handleFollowed=()=>{
        detail?.topicdetail?.followed_subtopics?.map(id=>
            document.getElementById(id).checked=true
            )
    }
    // useEffect(()=>{
    //     topics?.topicdetail?.followed_subtopics?.map(id=>
    //         document.getElementById(id).checked=true
    //         )
    //         // alert("Called")
    // },[topics])
    // console.log("list",list)

    return detail.loading ?(
        <Loader />
        // <h2>Loading</h2>
        ) : detail.error ? (
          <h2>{detail.error}</h2>
        ) : (
        <div className="topictab" onLoad={()=>handleFollowed()}>
            <NavigationBar />
            <div className="topictab-inside-div">
                <div className="topics-tab-heading text-center">Select topics and subtopics to include in the quiz</div>
                <Row className="topic-row">
                    <Col xs={12} lg={6}>
                    {
                    detail && detail?.topicdetail && detail?.topicdetail?.topic_detail && 
                        detail?.topicdetail?.topic_detail.map(item=>
                             item.id%2===0 ?
                            <div key={item.id} className="pt-3 pb-4 categories">
                                {console.log("item id ",item.id)}
                                <div className="parent icon" onClick={()=>history.push({pathname:'/topic-detail',state:{id:item.id}})}>
                                <ul>
                                <li>{item.name}</li>
                                </ul>
                                </div>
                                {
                                    // console.log("item",item.subtopic.length)
                                    // console.log("length",(item.subtopic.length/2))
                                    // (item.subtopic.length)/2 &&
                                    item.subtopic.map(aitem=>
                                <div key={aitem.id}>
                                <ul className="descendant myBorder icon">
                                <div className="horizontal line"></div>
                                <li><span onClick={()=>history.push({pathname:"/subtopic-detail",state:{id:aitem.id,name:item.name}})} >{aitem.name}</span>
                                <input type="checkbox" id={aitem.id} onClick={()=>FollowSubtopic(aitem.id)} />
                                </li>
                                <div className="vertical-line"></div>
                                </ul>
                                </div>
                                    )}
                            </div>
                            :<div></div>
                     )}
                     </Col>
                    <Col xs={12} lg={6}>
                            {
                            detail && detail.topicdetail && detail.topicdetail.topic_detail && 
                            detail?.topicdetail?.topic_detail?.map(item=>
                             item.id%2===1 ?
                            <div  key={item.id} className="pt-3 pb-4 categories">
                                {console.log("item id ",item.id)}
                                <div className="parent icon" onClick={()=>history.push({pathname:'/topic-detail',state:{id:item.id}})}>
                                <ul>
                                <li>{item.name}</li>
                                </ul>
                                </div>
                                {
                                    // console.log("item",item.subtopic.length)
                                    // console.log("length",(item.subtopic.length/2))
                                    // (item.subtopic.length)/2 &&
                                    item.subtopic.map(aitem=>
                                <div key={aitem.id}>
                                <ul className="descendant myBorder icon">
                                <div className="horizontal line"></div>
                                <li><span onClick={()=>history.push({pathname:"/subtopic-detail",state:{id:aitem.id,name:item.name}})} >{aitem.name}</span>
                                <input type="checkbox" id={aitem.id} onClick={()=>FollowSubtopic(aitem.id)} />
                                </li>
                                <div className="vertical-line"></div>
                                </ul>
                                </div>
                                    )}
                            </div>
                            :<div></div>
                     )}</Col>
                </Row>
                
            </div>
                <div className="text-center pt-5">
                        <Button className="save-and-play-button" onClick={()=>handleSaveButton()}>Save & Play</Button>
                </div>
        </div>
    )
}

export default TopicTab

